import { useMutation, useQuery } from 'react-query';
import { LunaChatRepository } from '../repositories/luna-chat.repository';

export const useSendLunaChatMessage = () => {
  const mutation = useMutation(
    (data: {
      organizationId: string;
      projectId: string;
      message: string;
      userId: string;
    }) => {
      return LunaChatRepository.postSendMessage(data);
    }
  );
  return mutation;
};

export const useGetLunaCreateProjectChatHistory = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getCreateProjectChatHistory`, data.organizationId, data.projectId],
    LunaChatRepository.getCreateProjectChatHistory,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response?.data;
      },
    }
  );
};

export const useSendLunaCreateProjectMessage = () => {
  const mutation = useMutation(
    (data: {
      organizationId: string;
      message: string;
      userId: string;
      requestId: string;
    }) => {
      return LunaChatRepository.postSendCreateProjectMessage(data);
    }
  );
  return mutation;
};
