import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { useInterval } from '../hooks';
import { useSession } from '.';
import {
  PolledDesignsType,
  DesignContextProviderProps,
  DesignStates,
  PolledDesignObjective,
  DesignResultType,
  DesignRouteParams,
} from '../../components/workspaces/adaptive-learning/types';
import { useScenarioDetail } from './scenario-detail-context';

import {
  ConstraintInputType,
  Objective,
  usefindDesignsByIterationLazyQuery,
  JobStatus,
  CostOptimizationOption,
  usesaveProjectConstraintsMutation,
  findDesignsByIterationQuery,
  usecancelProjectJobMutation,
  userequestDesignJobMutation,
  usecreateOneDesignMutation,
  useupsertManyObjectivesMutation,
  WorkspaceType,
  userequestQuickDesignMutation,
  Design,
} from '../../../../__generated__/globalTypes';
import { validateObjective } from '../utils/util';
import { processDesignResults } from '../../components/workspaces/adaptive-learning/design-utils';
import { useParams } from 'react-router-dom';
import { NO_IMPORTANCE } from '../../components/workspaces/shared/goals/types';
import { LOG_DESIGN_CONTEXT } from '../debug/flags';
import { useWorkspace } from './workspace-context';
import { useFormulations } from './formulations-context';
import { TrackableEvent, logEvent } from '../tracking/usage-tracker';
import { usePostMinCostForConstraints } from '../../network/services/constraint.service';
import { notification } from 'antd';

interface DesignContextProps {
  cancelRunningDesign: () => Promise<void>;
  designResults: DesignResultType[];
  designState: DesignStates;
  graphPointRef: MutableRefObject<null | Map<string, SVGSVGElement>>;
  hasSuccessfulDesign: boolean;
  latestDesign: PolledDesignsType | undefined;
  maxCostScore: number;
  minCostScore: number;
  setDesignState: any;
  previousDesigns: NonNullable<
    NonNullable<findDesignsByIterationQuery['iteration']>['designs']
  >;
  resultTableColumnRef: MutableRefObject<null | Map<string, HTMLElement>>;
  runDesignIsLoading: boolean;
  requestAndRunDesignJob: (createIterationTask?: boolean) => Promise<void>;
  runDesignJob: (
    designId: string,
    createIterationTask?: boolean
  ) => Promise<void>;
  runQuickDesign: ({
    passedConstraints,
    passedObjectivesByTarget,
    passedCostOptimizationOption,
    reasonMessage,
  }: {
    passedConstraints?: ConstraintInputType[];
    passedObjectivesByTarget?: Map<string, Objective>;
    passedCostOptimizationOption?: CostOptimizationOption;
    reasonMessage: string;
  }) => Promise<void>;
  quickDesignIsRunning: boolean;
  fetchLatestDesign: (designId: string) => Promise<void>;
  resetToLatestObjectives: () => void;
  minCostPosible: any[];
  runMinCostPosible: () => void;
}

const DesignContext = React.createContext<DesignContextProps>({} as any);
export const DesignContextProvider = ({
  children,
}: DesignContextProviderProps) => {
  const { currentProject, user, getSelectedIteration } = useSession();
  const {
    constraints,
    setConstraints,
    costOptimizationOption,
    setCostOptimizationOption,
    enforceNteCost,
    setEnforceNteCost,
    enforceStrictly,
    setEnforceStrictly,
    setErrorMessage,
    fillerIngredient,
    setFillerIngredient,
    maxNumberOfResults,
    setMaxNumberOfResults,
    nteCost,
    setNteCost,
    objectivesByTarget,
    setObjectivesByTarget,
    setOpenDrawer,
    templateFormulation,
    setHasNewConstraints,
  } = useScenarioDetail();
  const { refreshDesignFormulations } = useFormulations();
  const resultTableColumnRef = useRef(null);
  const graphPointRef = useRef(null);
  const currentModel = currentProject?.activeModel;
  const outcomes = currentModel?.outcomes;
  let { iteration } = useWorkspace();
  if (!iteration) {
    iteration = getSelectedIteration();
  }

  const [latestDesign, setLatestDesign] = useState<PolledDesignsType>();
  const [previousDesigns, setPreviousDesigns] = useState<
    NonNullable<
      NonNullable<findDesignsByIterationQuery['iteration']>['designs']
    >
  >(iteration?.designs ?? []);
  const params = useParams<DesignRouteParams>();
  /**
   * designResults - Parsed debugInfo field of the design call
   * associated with a product name
   *
   */

  const [designResults, setDesignResults] = useState<DesignResultType[]>([]);

  //Editing might not be a good first render state
  const [designState, setDesignState] = useState<DesignStates>(
    DesignStates.INITIAL
  );
  const [createDesign] = usecreateOneDesignMutation();
  const [runDesign, { loading }] = userequestDesignJobMutation();
  const [
    requestQuickDesign,
    { loading: quickDesignIsRunning },
  ] = userequestQuickDesignMutation();
  const [upsertManyObjectives] = useupsertManyObjectivesMutation();
  const [cancelDesignJob] = usecancelProjectJobMutation();
  const [fetchDesigns] = usefindDesignsByIterationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setPreviousDesigns(data.iteration?.designs ?? []);
    },
  });

  const [minCostScore, setMinCostScore] = useState<number>(0);
  const [maxCostScore, setMaxCostScore] = useState<number>(0);
  const [minCostPosible, setMinCostPosible] = useState<any[]>([]);

  const postMinCostForConstraints = usePostMinCostForConstraints();
  useEffect(() => {
    const currentFiller = currentProject?.ingredientList.find(
      ing => ing.filler
    );

    if (!fillerIngredient && currentFiller) {
      setFillerIngredient(currentFiller.ingredient.name);
    }
  }, [currentProject]);

  // Set design objectives
  useEffect(() => {
    // We don't want to overwrite the obejctives if we've selected an existing formulation to copy
    if (!templateFormulation) {
      resetToLatestObjectives();
    }
  }, [latestDesign?.objectives, iteration]);


  useEffect(() => {
    if (latestDesign?.minCost && latestDesign?.minCostFormulation) {
      setMinCostPosible([latestDesign?.minCost, latestDesign?.minCostFormulation])
    }
  }, [latestDesign?.minCost, latestDesign?.minCostFormulation])

  // Use either the default objectives or latest design objectives
  const resetToLatestObjectives = () => {
    // Very important to seed this map with te existing state.
    // Iteration level objectives are not added back to th iteration.objectives array after the page loads
    const objectivesByDateMap = new Map(objectivesByTarget);

    const projectObjectives = currentProject?.objectives.map(
      projectObjective => {
        const { __typename, ...formattedProjectObjective } = projectObjective;
        return formattedProjectObjective;
      }
    );

    const allObjectives = [
      ...(latestDesign?.objectives ?? []),
      ...(iteration?.objectives ?? []),
      ...(projectObjectives ?? []),
    ];

    for (const o of allObjectives) {
      const existingObjective = objectivesByDateMap.get(o.targetVariable);
      if (!existingObjective) {
        // Add if the key doesn't exist
        objectivesByDateMap.set(o.targetVariable, o);
      } else if (
        existingObjective.updatedAt &&
        o.updatedAt &&
        new Date(existingObjective.updatedAt).getTime() <
        new Date(o.updatedAt).getTime()
      ) {
        // Replace the objective with the newest record
        objectivesByDateMap.set(o.targetVariable, o);
      }
    }
    setMinMaxTargets(objectivesByDateMap);
  };

  const setMinMaxTargets = (objectivesByDateMap: Map<string, any>) => {
    const outcomes = currentProject?.activeModel?.outcomes;
    const updatedObjectivesMap = new Map<string, any>();

    objectivesByDateMap.forEach((objective: any, targetVariable: string) => {
      const matchedOutcome = outcomes?.find(
        (outcome: any) => outcome.targetVariable === objective.targetVariable
      );

      const updatedObjective = {
        ...objective,
        minTarget:
          matchedOutcome && objective.minTarget === null
            ? Number(matchedOutcome.lower)
            : objective.minTarget,
        maxTarget:
          matchedOutcome && objective.maxTarget === null
            ? Number(matchedOutcome.upper)
            : objective.maxTarget,
      };

      updatedObjectivesMap.set(targetVariable, updatedObjective);
    });

    setObjectivesByTarget(updatedObjectivesMap);
  };

  useEffect(() => {
    const iterationConstraintCount = Number(iteration?.constraints?.length);

    // Use the latest designs objectives
    if (latestDesign?.constraints && currentProject) {
      setConstraints(
        latestDesign.constraints.map(c => {
          const { __typename, ...cleanedConstraint } = c;
          return cleanedConstraint;
        })
          .concat(
            currentProject.iterations.find((i) => i.id === iteration?.id)?.constraints
              ?.filter(c => c.isPreDesign)
              .map(({ __typename, ...rest }) => rest) || []
          )
      );
      /**
       * Workspace level constraints, used after cloning a workspace
       * or when saving constraints before a design record has been created
       */
    } else if (iterationConstraintCount > 0) {
      if (iteration?.constraints) {
        setConstraints(
          iteration?.constraints.map(c => {
            const { __typename, ...cleanedConstraint } = c;
            return cleanedConstraint;
          })
        );
      }
    } else {
      // Revert to project level if others do not exist
      const projectConstraints =
        currentProject?.constraints?.map(
          (
            constraint: ConstraintInputType & {
              id?: string;
              __typename?: string;
            }
          ) => {
            const { __typename, ...rest } = constraint;
            return rest;
          }
        ) ?? [];
      setConstraints(projectConstraints);
    }
  }, [latestDesign?.constraints, iteration?.constraints, currentProject]);

  const fetchLatestDesign = async (designId?: string) => {
    if (iteration?.id) {
      let { data } = await fetchDesigns({
        variables: {
          id: iteration.id,
          designId,
        },
      });
      const designFromQuery = data?.iteration?.latestDesign;
      if (designFromQuery) {
        setLatestDesign(designFromQuery);
        if (designFromQuery?.maxNumberOfResults) {
          setMaxNumberOfResults(designFromQuery?.maxNumberOfResults);
        }
        if (
          iteration.type === WorkspaceType.EXPLORATION &&
          latestDesign?.projectJob?.status === JobStatus.IN_PROGRESS &&
          designFromQuery?.projectJob?.status === JobStatus.SUCCESS
        ) {
          refreshDesignFormulations(designFromQuery.id);
        }
      } else if (!user?.enableIceCreamBetaFeatures) {
        setOpenDrawer(true);
      }
    }
  };

  useEffect(() => {
    if (iteration && params.designId) {
      // ? This fetches a specific design when the designId is passed.
      fetchLatestDesign(params.designId);
    } else if (iteration) {
      fetchLatestDesign();
    }
  }, [iteration, params.designId]);

  useEffect(() => {
    if (latestDesign) {
      try {
        const packagedResults =
          processDesignResults(latestDesign, currentProject) ?? [];
        setDesignResults(packagedResults);
        let highestScore = 0;
        let lowestScore = 0;
        const existingFormulations = packagedResults.filter(
          result => !result.isGenerated
        );
        for (const result of existingFormulations) {
          const costScore = Number(result.formulation.totalCostScore);
          if (costScore > highestScore) {
            highestScore = Number(costScore);
          }
          if (!lowestScore || costScore < lowestScore) {
            lowestScore = Number(costScore);
          }
        }
        setMinCostScore(lowestScore);
        setMaxCostScore(highestScore);
        if (!constraints?.length) {
          setConstraints(
            latestDesign.constraints.map(c => {
              const { __typename, ...c2 } = c;
              return c2;
            })
          );
        }
        setNteCost(Number(latestDesign?.nteCost));
        setEnforceNteCost(latestDesign?.enforceNteCost ?? false);
        setEnforceStrictly(latestDesign?.enforceStrictly ?? true);
        setCostOptimizationOption(
          latestDesign?.costOptimizationOption ??
          CostOptimizationOption.DO_NOT_OPTIMIZE
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [latestDesign, currentProject]);

  useEffect(() => {
    let newState = DesignStates.INITIAL;

    switch (latestDesign?.projectJob?.status) {
      case JobStatus.CANCELED:
        newState = DesignStates.EDITING;
        break;
      case JobStatus.ERROR:
        newState = DesignStates.ERROR;
        break;
      case JobStatus.IN_PROGRESS:
      case JobStatus.PENDING:
        newState = DesignStates.RUNNING;
        break;
      case JobStatus.SUCCESS:
        if (latestDesign.product) {
          newState = DesignStates.FINISHED;
        } else {
          newState = DesignStates.NO_RESULTS;
        }
    }

    setDesignState(newState);
  }, [latestDesign]);

  const runObjectiveValidation = (o: Objective) => {
    const outcome = outcomes?.find(
      outc => outc.targetVariable === o.targetVariable
    );
    let error = validateObjective(o, outcome);
    if (error) {
      setErrorMessage(error.message);
      setDesignState(DesignStates.ERROR);
      throw Error(error.message);
    }
  };
  const validateCostOptimization = () => {
    if (
      costOptimizationOption === CostOptimizationOption.LIMIT &&
      (nteCost === 0 || nteCost === null)
    ) {
      const errorMessage =
        'Cost not to exceed must be a valid number and greater than 0';
      setErrorMessage(errorMessage);
      setDesignState(DesignStates.ERROR);
      throw Error(errorMessage);
    }
  };

  const runQuickDesign = async ({
    passedConstraints,
    passedObjectivesByTarget,
    passedCostOptimizationOption,
    reasonMessage,
  }: {
    passedConstraints?: ConstraintInputType[];
    passedObjectivesByTarget?: Map<string, Objective>;
    passedCostOptimizationOption: CostOptimizationOption;
    reasonMessage: string;
  }) => {
    logEvent(TrackableEvent.DISCOVER_SOLUTION_QUICK_ADAPTIVE_LEARNING_RUN);
    const objectivesToSave: Objective[] = [];
    let objectiveIds: string[] = [];
    // Use the arguement objectives if they're passed, otherwise use the objectives in state
    const objectiveMap = passedObjectivesByTarget ?? objectivesByTarget;
    const costOption = passedCostOptimizationOption ?? costOptimizationOption;
    for (const [, objective] of objectiveMap) {
      runObjectiveValidation(objective);
      const { __typename, ...cleanedObjective } = objective;
      objectivesToSave.push(cleanedObjective);
    }

    if (objectivesToSave.length > 0 && iteration?.id) {
      const updatedObjectives = await upsertManyObjectives({
        variables: {
          iterationId: iteration.id,
          objectives: objectivesToSave,
        },
      });
      objectiveIds =
        updatedObjectives.data?.upsertManyObjectives.map(
          objective => objective.id
        ) ?? [];
    }

    validateCostOptimization();

    const constraintsToUse = passedConstraints ?? constraints;
    const constraintIds =
      constraintsToUse.map(constraint => constraint.id) ?? [];

    try {
      setDesignState(DesignStates.RUNNING);
      let response = await requestQuickDesign({
        variables: {
          iterationId: iteration!.id,
          objectiveIds,
          constraintIds: constraintIds as string[],
          maxNumberOfResults: 3,
          nteCost,
          enforceNteCost,
          costOptimizationOption: costOption,
          enforceStrictly,
          reasonMessage,
          fillerIngredient,
        },
      });

      setHasNewConstraints(false);
      const designId = response.data?.runQuickDesign?.id;
      if (designId) {
        await refreshDesignFormulations(designId);
      }
      await fetchLatestDesign();
    } catch (e: any) {
      const errorJson = await JSON.stringify(e);
      const parseObject = JSON.parse(errorJson);
      const errorDetail =
        parseObject?.graphQLErrors[0]?.extensions?.exception?.errorDetail;



      if (!errorDetail) {

        const errMessageStringfy = JSON.stringify(e)
        if (errMessageStringfy.includes('timeout')) {
          setErrorMessage('A machine learning timeout occurred. Please try again later or modify your request by reducing the number of constraints especially category constraints if relevant.')
        }
        // else if (errMessageStringfy.includes('ECONNREFUSED')) {
        //   setErrorMessage('Unable to establish a connection to the machine learning API. Please try again later')
        // }
        else {
          setErrorMessage('')
        }
      }
      else if (errorDetail.includes('Could not find samples matching all constraints')) {
        setErrorMessage('Suggestions could not be generated for the provided set of constraints. Please adjust your constraints and try again')
      }
      else {
        setErrorMessage(errorDetail);
      }
      setDesignState(DesignStates.ERROR);
    }
  };

  const runMinCostPosible = async () => {
    setMinCostPosible([]);
    postMinCostForConstraints.mutate(
      {
        iterationId: iteration?.id ?? '0',
        organizationId: user?.organizationId ?? '',
        projectId: currentProject?.id ?? '',
        upsertManyConstraintsData: constraints,
      }, {
      onSuccess: (minCost) => {
        if (minCost.data.status) {
          setMinCostPosible(minCost.data.data);
        } else {
          notification.warning({ message: 'Unable to obtain the minimum cost with this set of constraints.' });
        }
      },
      onError: () => {
        notification.warning({ message: 'Unable to obtain the minimum cost with this set of constraints.' });
      }
    }
    );
  }

  const requestAndRunDesignJob = async (createIterationTask?: boolean) => {
    /**
     * Check for unsaved objectives -- this should mainly apply to
     * iterations created before Objectives/Constraints were saved at creation
     */
    const objectivesToSave: Objective[] = [];
    let objectiveIds: string[] = [];

    for (const [, objective] of objectivesByTarget) {
      runObjectiveValidation(objective);
      const { __typename, ...cleanedObjective } = objective;
      objectivesToSave.push(cleanedObjective);
    }

    if (objectivesToSave.length > 0 && iteration?.id) {
      const updatedObjectives = await upsertManyObjectives({
        variables: {
          iterationId: iteration.id,
          objectives: objectivesToSave,
        },
      });
      objectiveIds =
        updatedObjectives.data?.upsertManyObjectives.map(
          objective => objective.id
        ) ?? [];
    }

    validateCostOptimization();

    let designId: string | undefined;
    const constraintIds = constraints.map(constraint => constraint.id) ?? [];

    try {
      const createDesignResponse = await createDesign({
        variables: {
          iterationId: iteration!.id,
          objectiveIds,
          constraintIds: constraintIds as string[],
          maxNumberOfResults: Number(maxNumberOfResults),
          nteCost,
          enforceNteCost,
          costOptimizationOption,
          enforceStrictly,
        },
      });

      designId = createDesignResponse.data?.createOneDesign.id;
    } catch (e) {
      const message = 'Error creating Adaptive Learning.';
      setErrorMessage(message);
      setDesignState(DesignStates.ERROR);
    }

    await runDesignJob(designId!, createIterationTask);

    fetchLatestDesign();
  };

  const runDesignJob = async (
    designId: string,
    createIterationTask?: boolean
  ) => {
    try {
      await runDesign({
        variables: {
          designId: designId!,
          fillerIngredient,
          debug: LOG_DESIGN_CONTEXT,
          createIterationTask,
        },
      });
      setDesignState(DesignStates.RUNNING);
    } catch (e) {
      const message = 'Error starting Adaptive Learning.';
      setErrorMessage(message);
      setDesignState(DesignStates.ERROR);
    }
  };

  const cancelRunningDesign = async () => {
    if (latestDesign?.projectJob?.id) {
      await cancelDesignJob({
        variables: {
          projectJobId: latestDesign.projectJob.id,
        },
      });
    }
    setDesignState(DesignStates.INITIAL);
    setLatestDesign(undefined);
    await fetchLatestDesign();
  };

  // Checks every 5 seconds if the DB has been updated by the polling service.
  useInterval(async () => {
    if (designState === DesignStates.RUNNING) {
      fetchLatestDesign();
    }
  }, 5000);

  const hasSuccessfulDesign = previousDesigns.some(
    d => d?.projectJob?.status === 'SUCCESS' && d?.productId
  );

  if (LOG_DESIGN_CONTEXT) {
    console.log('Design Context', {
      cancelRunningDesign,
      designResults,
      designState,
      graphPointRef,
      hasSuccessfulDesign,
      latestDesign,
      maxCostScore,
      minCostScore,
      previousDesigns,
    });
  }

  return (
    <DesignContext.Provider
      value={{
        cancelRunningDesign,
        designResults,
        designState,
        graphPointRef,
        hasSuccessfulDesign,
        latestDesign,
        maxCostScore,
        minCostScore,
        previousDesigns,
        resultTableColumnRef,
        runDesignIsLoading: loading,
        requestAndRunDesignJob,
        runDesignJob,
        runQuickDesign,
        quickDesignIsRunning,
        fetchLatestDesign,
        resetToLatestObjectives,
        setDesignState,
        minCostPosible,
        runMinCostPosible
      }}
    >
      {children}
    </DesignContext.Provider>
  );
};

export const useDesign = () => useContext(DesignContext);
