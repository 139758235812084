import { useSession } from '../../_shared/context/session-context';
import {
  FormulationFeedbackSubjectType,
  FormulationFeedbackType,
  FormulationSource,
  FormulationStatus,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useUpdateFolderNameMutation,
  usegetIterationFoldersQuery,
  usesetFormulationNameMutation,
} from '../../../../__generated__/globalTypes';
import {
  Button,
  TreeDataNode,
  Typography,
  notification,
  Input,
  Flex,
  Popover,
} from 'antd';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  FileTextOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import {
  FormulationReducerActionType,
  FormulationType,
  useFormulations,
  useFormulationsDispatch,
} from '../../_shared/context/formulations-context';
import Tree, { DataNode } from 'antd/es/tree';
import { useDebouncedEffect } from '../../_shared/utils/util';
import _, { remove } from 'lodash';


import { useExploration } from '../../_shared/context';
import { ExperimentFolder } from './components/experiments-folders';
import { Like } from '../../_shared/style';
import './exploration-tree-styles.less';
import InitiativesLoader from './components/initiatives-loader';
import { useDesign } from '../../_shared/context/design-context';
import { useRetryFailedCampaign } from '../../network/services/campaign.service';
import { useScenarioDetail } from '../../_shared/context/scenario-detail-context';
import useWebSocket from '../../_shared/hooks/use-web-socket.hook';
const { Text } = Typography;

export enum FolderKeys {
  ITERATION_NAME = 'ITERATION_NAME',
  TURING_SUGGESTIONS = 'TURING_SUGGESTIONS',
}
const { Search } = Input;
const iconSyles = { color: '#b1b5b8', fontSize: 20 };

type FeedBackData = {
  type: FormulationFeedbackType;
  subject: FormulationFeedbackSubjectType;
};

export const stateFailed = 'FAILED';

const RenderTitle = ({
  node,
  onClick,
  expanded,
  projectFormulations,
  parent,
  foldersFromServer,
  onRefetchFoldersFromServer
}: {
  node: TreeDataNode & {
    id: string;
    createdAt: string;
    isLoading: boolean;
    isFeedback: boolean;
    dataFeedback: FeedBackData;
    state: string;
    errorMessage: string;
  };
  onClick(key: string): void;
  expanded: boolean;
  projectFormulations: FormulationType[];
  parent?: boolean;
  foldersFromServer?: any
  onRefetchFoldersFromServer(): void
}): React.ReactNode => {
  const [editMode, setEditMode] = useState(false);
  const [
    editModeFormulationInSubFolder,
    seteditModeFormulationInSubFolder,
  ] = useState<boolean>(false);
  const dispatch = useFormulationsDispatch();
  const nodeIsIterationName = node.key === FolderKeys.ITERATION_NAME;
  const [
    updateFormulationName,
    { data, loading, error },
  ] = usesetFormulationNameMutation();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const {
    quickDesignIsRunning
  } = useDesign();
  const retryFailedCampaign = useRetryFailedCampaign();
  const {
    setCurrentProject,
    currentProject,
    user: currentUser,
    selectedIterationId,
    setLoadFolder,
  } = useSession();
  const {
    costOptimizationOption,
    nteCost,
    enforceNteCost
  } = useScenarioDetail();

  const nodeIsParent = parent
    ? true
    : nodeIsIterationName || node.key === FolderKeys.TURING_SUGGESTIONS;
  let formulation: FormulationType | undefined;
  let formulationFeedBack: any;

  const isFeedbackLeaf = node.isFeedback;
  const feedbackData = node.dataFeedback;

  if (!nodeIsParent) {
    formulation = projectFormulations.find(
      projectFormulation => projectFormulation.key === node.key
    );
    const feedbackList = formulation?.formulationFeedback ?? [];

    if (feedbackList.length > 0) {
      formulationFeedBack = feedbackList[feedbackList.length - 1];
    }
  }


  let itemClassName = 'exploration-tree-item';

  if (expanded && node.title !== 'Benchmark') {
    itemClassName += ' exploration-tree-item-selected';
  }

  if (!nodeIsIterationName) {
    itemClassName += ' bordered';
  }

  const handleUpdateFormulationName = async (
    value: string,
    id: string,
    isFormulationFromSubHolder?: boolean
  ) => {
    let updatedFormulation = await updateFormulationName({
      variables: {
        formulationId: id, // value for 'formulationId'
        name: value, // value for 'status'
      },
    });
    if (updatedFormulation.data?.setFormulationName) {
      dispatch({
        type: FormulationReducerActionType.CHANGED,
        formulation: updatedFormulation.data?.setFormulationName,
      });
    }

    isFormulationFromSubHolder
      ? seteditModeFormulationInSubFolder(!editModeFormulationInSubFolder)
      : setEditMode(!editMode);
  };


  const handleRetrailFormulation = async (node: any, formulation: any) => {
    let folder = foldersFromServer.folder.filter(
      (item: any) => item.id === node.key
    )[0];

    const campaignName = folder.campaign.name;;
    const numberIterations = folder.campaign.numberIterations;
    const numberFormulations = folder.campaign.numberFormulations;
    const campaignId = folder.campaign.id;
    setOpen(false);
    if (!quickDesignIsRunning) {
      retryFailedCampaign.mutate(
        {
          numberIterations,
          numberFormulations,
          projectId: currentProject?.id,
          createdById: currentUser,
          iterationId: selectedIterationId,
          costOptimizationOption: costOptimizationOption,
          nteCost,
          enforceNteCost,
          reasonMessage: 'Running Quick AL',
          campaignName,
          campaignId,
          user: currentUser
        },
        {
          onSuccess: async response => {
            if (response.data.status) {
              notification.success({
                message: 'Generating recommendations.Please check test plan',
              });
              setLoadFolder(true);
              if (currentProject)
                setCurrentProject({
                  ...currentProject,
                  currentCampaignId: response.data.data.id as string,
                });
            }
          },
          onError: async response => {
            notification.error({
              message:
                'Something went wrong; we couldn’t complete the retry.',
            });
          },
        }
      );
    }
  }
  const content = (node: any) => (
    <>
      <div className='retry-content'>
        <p>{node.errorMessage} </p>
        <button className='ant-btn css-dev-only-do-not-override-xu9wm8 ant-btn-default' onClick={() => handleRetrailFormulation(node, formulation)}>Click to Retry</button>
      </div>
    </>
  );



  return (
    <>
      <div
        className={
          nodeIsParent
            ? 'folder-header-item' + ' ' + itemClassName
            : itemClassName
        }
      >
        <span>
          {node.key === FolderKeys.TURING_SUGGESTIONS ||
            node.key === FolderKeys.ITERATION_NAME ||
            !nodeIsParent ? (
            nodeIsParent &&
            (expanded ? (
              <FolderOpenOutlined style={iconSyles} />
            ) : (
              <FolderOutlined style={iconSyles} />
            ))
          ) : (
            <></>
          )}
        </span>
        <Text
          className="exploration-tree-item-text"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
          }}
        >
          {!nodeIsParent && <FileTextOutlined style={iconSyles} />}
          {!editMode ? (
            <>
              {node.isLoading && node.state != stateFailed && (
                <InitiativesLoader
                  isFinished={true}
                  creationDate={node.createdAt}
                />
              )}
              <span
                onClick={() => onClick(node.key as string)}
                style={{ width: '100%' }}
              >
                {node.title as ReactNode}
              </span>
              {
                node.state === stateFailed
                // true
                && (
                  <Popover
                    content={content(node)}
                    title=""
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                  >
                    <ExclamationCircleOutlined className='exclamation-circle-formulation-failed' />
                  </Popover>
                )
              }
            </>
          ) : (
            <Search
              enterButton="Save"
              defaultValue={node.title as string}
              allowClear
              onSearch={value =>
                value
                  ? handleUpdateFormulationName(value, node.id)
                  : setEditMode(false)
              }
            />
          )}
          {!nodeIsParent && !editMode && (
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setEditMode(!editMode)}
            />
          )}
          {!nodeIsParent &&
            isFeedbackLeaf &&
            node.dataFeedback?.type === FormulationFeedbackType.POSITIVE && (
              <>
                <div className="like complete">
                  <Like />
                </div>
                <span style={{ marginLeft: '0.5rem', width: '-webkit-fill-available' }}>
                  {`(${node.dataFeedback?.subject})`}
                </span>
              </>
            )}
          {!nodeIsParent &&
            isFeedbackLeaf &&
            node.dataFeedback?.type === FormulationFeedbackType.NEGATIVE && (
              <>
                <div className="dislike complete">
                  <Like />
                </div>
                <span style={{ marginLeft: '0.5rem', width: '-webkit-fill-available' }}>
                  {`(${node.dataFeedback?.subject})`}
                </span>
              </>
            )}
        </Text>
        {!nodeIsParent && formulation?.createdBy && (
          <>

            <Text type="secondary" style={{ fontSize: 12 }}>
              Created by:{formulation?.createdBy.firstName}{' '}
              {formulation?.createdBy.lastName} -{' '}
              {new Date(formulation.createdAt).toLocaleDateString()}
            </Text>
          </>
        )}
      </div>
    </>
  );
};

type TreeDataWithID = TreeDataNode & {
  id: string;
  createdAt: string;
  isLoading: boolean;
  isFeedback: boolean;
  dataFeedback: FeedBackData;
  state: string;
};

export const ExplorationTree = ({
  selectedFormulation,
  handleChangeFormulationToView,
}: {
  selectedFormulation?: FormulationType;
  handleChangeFormulationToView: (formulation: FormulationType) => void;
}) => {
  const {
    currentProject,
    getSelectedIteration,
    loadFolder,
    setLoadFolder,
  } = useSession();
  const { setFormulationStatus } = useExploration();
  const currentIteration = getSelectedIteration();
  const {
    projectBenchmarkFormulation,
    projectFormulations,
    refreshFeedbackFormulations
  } = useFormulations();
  const [createFolder] = useCreateFolderMutation();
  const [updateFolder] = useUpdateFolderNameMutation();
  const [deleteFolderQl] = useDeleteFolderMutation();
  const [isFoldersFromServerFetched, setIsFoldersFromServerFetched] = useState<
    boolean
  >(false);
  const {
    data: foldersFromServer,
    refetch: refetchFoldersFromServer,
  } = usegetIterationFoldersQuery({
    variables: {
      projectId: currentProject?.id ?? '',
      iterationId: currentIteration?.id ?? '',
    },
  });
  const [subFolders, setSubFolders] = useState<
    {
      key: string;
      name: string;
      isEdit?: boolean;
      parentId?: string;
      campaignId?: string;
      formulation_children?: DataNode[];
      createdAt: string;
      isLoading: boolean;
      state: string;
      errorMessage: string;
    }[]
  >([]);
  const { expandFormulations, collapsed } = useExploration();
  const [folderTreeData, setFolderTreeData] = useState<TreeDataWithID[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [feedbackFormulation, setFeedbackFormulation] = useState<any[]>([]);
  const [pinnedFormulations, setPinnedFormulations] = useState<
    FormulationType[]
  >([]);


  useEffect(() => {
    if (loadFolder) {
      refreshFeedbackFormulations()
      refetchFoldersFromServer().then(() => {
        setIsFoldersFromServerFetched(false);
        subFolders.map(f => {
          f.isLoading = false;
        });
        setLoadFolder(false);
      });
    }
  }, [loadFolder]);

  const { loadTestPlanError, refresh } = useWebSocket();

  useEffect(() => {
    refetchFoldersFromServer().then(() => {
      setIsFoldersFromServerFetched(false);
      subFolders.map(f => {
        f.isLoading = false;
      });
      setLoadFolder(false);
    });
  }, [loadTestPlanError, refresh])

  useEffect(() => {
    const selectedIteration = getSelectedIteration();

    setPinnedFormulations(
      projectFormulations.filter(
        formulation =>
          (formulation.status === FormulationStatus.PINNED &&
            formulation.source === FormulationSource.SUGGESTED &&
            formulation.iterationId === selectedIteration?.id) ||
          formulation.folderId
      )
    );

    // FEEDBACK
    // let formulationWFeedback = projectFormulations.filter(formulation => (
    //   formulation.formulationFeedback.length > 0 &&
    //   formulation.formulationFeedback[formulation.formulationFeedback.length - 1].isActive
    // )).map(f => ({
    //   title: f.name ?? f.key,
    //   key: `${f.key}` ?? f.id,
    //   className: 'exploration-tree-item-container',
    //   id: f.id,
    //   isFeedback: true,
    //   dataFeedback: {
    //     type: f.formulationFeedback[f.formulationFeedback.length - 1].type,
    //     subject: f.formulationFeedback[f.formulationFeedback.length - 1].subjectType
    //   }
    // }))

    let formulationWFeedback: any[] = [];
    projectFormulations
      .filter(
        formulation =>
          formulation.formulationFeedback.length > 0 &&
          formulation.formulationFeedback[
            formulation.formulationFeedback.length - 1
          ].isActive
      )
      .forEach(f => {
        let outcomeFeedback = false;
        let ingredientFeedback = false;

        const historicFormulationFeedback = f.formulationFeedback
          .filter(feedback => feedback.isActive)
          .reverse();

        historicFormulationFeedback.forEach(hff => {
          if (
            hff.subjectType === FormulationFeedbackSubjectType.OUTCOME &&
            !outcomeFeedback
          ) {
            formulationWFeedback.push({
              title: f.name ?? f.key,
              key: `${f.key}-O` ?? f.id,
              className: 'exploration-tree-item-container',
              id: f.id,
              isFeedback: true,
              dataFeedback: {
                type: hff.type,
                subject: FormulationFeedbackSubjectType.OUTCOME,
              },
            });

            outcomeFeedback = true;
          }

          if (
            hff.subjectType === FormulationFeedbackSubjectType.INGREDIENT &&
            !ingredientFeedback
          ) {
            formulationWFeedback.push({
              title: f.name ?? f.key,
              key: `${f.key}-I` ?? f.id,
              className: 'exploration-tree-item-container',
              id: f.id,
              isFeedback: true,
              dataFeedback: {
                type: hff.type,
                subject: FormulationFeedbackSubjectType.INGREDIENT,
              },
            });

            ingredientFeedback = true;
          }
        });
      });

    setFeedbackFormulation(formulationWFeedback);
  }, [projectFormulations, getSelectedIteration()?.id]);

  useEffect(() => {
    if (
      foldersFromServer &&
      foldersFromServer.folder &&
      !isFoldersFromServerFetched
    ) {
      const aux: any = [];
      foldersFromServer.folder.forEach(f => {
        if (!f?.campaign?.isSoftDeleted) {
          aux.push({
            name: f?.name ?? 'New Folder',
            key: f?.id ?? Math.random().toString(),
            parentId: f?.parentId,
            formulation_children: filterFolderChildren(f?.id ?? '') || [],
            campaignId: f?.campaignId,
            createdAt: f?.createdAt,
            isLoading: f?.isLoading,
            state: f?.campaign?.state,
            errorMessage: f?.campaign?.errorMessage
          });
        }
      });
      setIsFoldersFromServerFetched(true);
      setSubFolders(checkFailedCampaign(aux));
    }
  }, [foldersFromServer, isFoldersFromServerFetched]);

  const checkFailedCampaign = (aux: any) => {

    const grouped = aux
      .filter((item: any) => item.state === stateFailed)
      .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .reduce((grouped: Record<string, any[]>, item: any) => {
        if (!grouped[item.campaignId]) {
          grouped[item.campaignId] = [];
        }
        grouped[item.campaignId].push(item);
        return grouped;
      }, {});

    Object.values(grouped).forEach((group: any[]) => {
      group.forEach((item, index) => {
        if (index !== 0) {
          item.state = "FINISHED";
        }
      });
    });

    return aux
  }

  useEffect(() => {
    const selectedIteration = getSelectedIteration();

    const pinneFormulationsRoot = pinnedFormulations.filter(f => {
      return f.folderId === null;
    });

    const turingSuggestions: DataNode[] = pinneFormulationsRoot
      .map(
        formulation =>
        ({
          title: formulation.name ?? formulation.key,
          key: formulation.key ?? formulation.id,
          className: 'exploration-tree-item-container',
          id: formulation.id,
        } as DataNode)
      )
      .concat([
        // FEEDBACK FOLDER
        {
          title: (
            <ExperimentFolder
              folder={{
                key: 'FEEDBACK',
                name: 'Feedback',
                campaignId: 'FEEDBACK',
              }}
              deleteFolder={deleteFolder}
              editFolderName={editFolderName}
              toogleEditMode={toogleEditMode}
              handleExpandFolder={() => handleExpandFolder('FEEDBACK')}
              openedFolder={feedbackFormulation.length > 0}
              isEditMode={false}
              editableFolder={false}
              isFeedbackFolder={true}
            ></ExperimentFolder>
          ),
          key: 'FORMULATION-FEEDBACK',
          className: 'exploration-tree-item-container',
          id: 'FEEDBACK-FORMULATION',
          children: feedbackFormulation,
          expanded: true,
          isFeedback: true
        } as DataNode,
      ])
      .concat(
        subFolders.map(sf => {
          const _children = filterFolderChildren(sf.key);
          return {
            title: (
              <ExperimentFolder
                folder={sf}
                deleteFolder={deleteFolder}
                editFolderName={editFolderName}
                toogleEditMode={toogleEditMode}
                handleExpandFolder={() => handleExpandFolder(sf.key)}
                openedFolder={_children ? _children.length > 0 : false}
                isEditMode={sf.isEdit}
                softDeleteFolder={softDeleteFolder}
              ></ExperimentFolder>
            ),
            key: sf.key,
            className: 'exploration-tree-item-container',
            children: _children,
            expanded: true,
            createdAt: sf.createdAt,
            isLoading: sf.isLoading,
            state: sf.state,
            isFeedback: false,
            errorMessage: sf.errorMessage
          };
        })
      );

    if (selectedIteration) {
      const treeData: TreeDataNode[] = [
        {
          title: selectedIteration.name,
          key: FolderKeys.ITERATION_NAME,
          children: [
            ...((projectBenchmarkFormulation && [
              {
                title: 'Benchmark',
                key:
                  projectBenchmarkFormulation.key ??
                  projectBenchmarkFormulation.id,
              },
            ]) ??
              []),
            {
              title: () => (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <FolderOutlined style={iconSyles} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      Turing Suggestions
                    </span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        <Button
                          type="text"
                          icon={<FolderAddOutlined />}
                          onClick={addFolder}
                          style={iconSyles}
                        />
                        <Button
                          type="text"
                          icon={<ExportOutlined />}
                          style={iconSyles}
                          onClick={() =>
                            handleExpandFolder(FolderKeys.TURING_SUGGESTIONS)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ),
              key: FolderKeys.TURING_SUGGESTIONS,
              children: turingSuggestions,
            },
          ],
        },
      ];
      setFolderTreeData(treeData);
    }
  }, [pinnedFormulations, getSelectedIteration()?.name, subFolders]);

  const handleNodeOnClick = (key: string) => {
    if (key === projectBenchmarkFormulation?.key) {
      handleChangeFormulationToView(projectBenchmarkFormulation);
    }
    return handleExpandParentNode(key);
  };

  const handleExpandParentNode = (key: string) => {
    let updatedExpandedKeys = [...expandedKeys];
    // console.log({ updatedExpandedKeys })
    const nodeIndex = updatedExpandedKeys.findIndex(
      expandedKey => expandedKey === key
    );

    if (nodeIndex !== -1) {
      updatedExpandedKeys.splice(nodeIndex, 1);
    } else {
      updatedExpandedKeys.push(key);
    }

    setExpandedKeys(updatedExpandedKeys);
  };

  const handleExpandFolder = (folderKey?: string) => {
    if (folderKey === FolderKeys.TURING_SUGGESTIONS) {
      expandFormulations(pinnedFormulations);
    } else if (folderKey === 'FEEDBACK') {
      const formulationsToExpand = projectFormulations
        .filter(
          formulation =>
            formulation.formulationFeedback.length > 0 &&
            formulation.formulationFeedback[
              formulation.formulationFeedback.length - 1
            ].isActive
        )
      expandFormulations(formulationsToExpand);
    } else {
      const formulationToExpand = pinnedFormulations.filter(f => {
        return f.folderId === folderKey;
      });
      expandFormulations(formulationToExpand);
    }
  };

  useDebouncedEffect(
    () => {
      setExpandedKeys([
        FolderKeys.ITERATION_NAME,
        FolderKeys.TURING_SUGGESTIONS,
      ]);
    },
    [],
    10 // add 10ms delay to allow tree to render before setting expandedKeys
  );

  const addFolder = async () => {
    if (!currentIteration || !currentProject) return;

    const res = await createFolder({
      variables: {
        iterationId: currentIteration.id,
        name: 'New Folder',
        projectId: currentProject.id,
        parentFolderId: '',
      },
    });

    const aux = [...subFolders];
    aux.push({
      key: res.data?.createFolder.id ?? '',
      name: res.data?.createFolder.name ?? 'New Folder',
    });
    setSubFolders(aux);
    refetchFoldersFromServer();
  };

  const toogleEditMode = (_id: string) => {
    const index = subFolders.findIndex(e => {
      return e.key === _id;
    });

    const newSubFoldersArr = subFolders.map((f, i) => {
      if (i === index) {
        f.isEdit = true;
        return f;
      } else {
        return f;
      }
    });

    setSubFolders(newSubFoldersArr);
  };

  const editFolderName = async (updateFolderData: {
    id: string;
    newName: string;
  }) => {
    const res = await updateFolder({
      variables: {
        folderId: updateFolderData.id,
        newName: updateFolderData.newName,
      },
    });

    const index = subFolders.findIndex(e => {
      return e.key === updateFolderData.id;
    });

    const newSubFoldersArr = subFolders.map((f, i) => {
      if (i === index) {
        f.isEdit = false;
        f.name = updateFolderData.newName;
        return f;
      } else {
        return f;
      }
    });

    setSubFolders(newSubFoldersArr);
  };

  const deleteFolder = async (_id: string) => {
    setPinnedFormulations(
      pinnedFormulations.map(pf => {
        if (pf.folderId === _id) {
          pf.folderId = null;
          return pf;
        } else {
          return pf;
        }
      })
    );

    const res = await deleteFolderQl({
      variables: {
        id: _id,
      },
    });

    const aux = subFolders.filter(f => {
      return f.key !== _id;
    });
    setSubFolders(aux);
  };

  const softDeleteFolder = async (id: string) => {

  }

  const filterFolderChildren = (folderKey: string): DataNode[] => {
    const childrenFormulations = pinnedFormulations
      .filter(f => f.folderId === folderKey)
      .map(f => ({
        title: f.name ?? f.key,
        key: `${f.key}` ?? f.id,
        className: 'exploration-tree-item-container',
        id: f.id,
      }));

    const childrenFolders = subFolders
      .filter(folder => folder.parentId === folderKey)
      .map(folder => ({
        title: (
          <ExperimentFolder
            folder={folder}
            deleteFolder={deleteFolder}
            editFolderName={editFolderName}
            toogleEditMode={toogleEditMode}
            handleExpandFolder={() => handleExpandFolder(folder.key)}
            openedFolder={filterFolderChildren(folder.key).length > 0}
            isEditMode={folder.isEdit}
          />
        ),
        key: folder.key,
        className: 'exploration-tree-item-container',
        children: filterFolderChildren(folder.key),
        createdAt: folder.createdAt,
        isLoading: folder.isLoading,
        state: folder.state
      }));

    return [...childrenFormulations, ...childrenFolders];
  };


  return (
    <div id="exploration-folder-tree" style={{
      width: '100%'
    }}>
      <Tree
        showIcon
        treeData={folderTreeData}
        expandedKeys={expandedKeys}
        switcherIcon={<></>}
        selectedKeys={selectedFormulation?.key ? [selectedFormulation.key] : []}
        blockNode
        selectable={true}
        titleRender={node => (
          <RenderTitle
            selectedFormulation={selectedFormulation}
            node={node}
            onClick={handleNodeOnClick}
            expanded={expandedKeys.includes(node.key as string)}
            projectFormulations={projectFormulations}
            parent={node.children ? true : false}
            foldersFromServer={foldersFromServer}
            onRefetchFoldersFromServer={refetchFoldersFromServer}
          />
        )}
      />
    </div>
  );
};
