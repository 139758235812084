import { useMutation, useQuery } from 'react-query';
import {
  CreateIngredientOrgData,
  UploadIngredientSpec,
} from '../interfaces/ingredient.interfaces';
import { IngredientOrgRepository } from '../repositories/ingredient-org.repository';


export const useGetIngredientsByOrg = (data: {
  organizationId: string;
  page: number;
  pageSize: number;
  sortField: string;
  sortOrder: string | undefined;
  searchTerm: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getIngredientsByOrg`,
      data.organizationId,
      data.page,
      data.pageSize,
      data.sortField,
      data.sortOrder,
      data.searchTerm
    ],
    IngredientOrgRepository.getIngredientsByOrg,
    {
      enabled: !!data.organizationId, // Or any other condition to enable the query
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

export const useUploadIngredientSpecs = () => {
  const mutation = useMutation((data: UploadIngredientSpec) => {
    return IngredientOrgRepository.postUploadIngredientSpec(data);
  });
  return mutation;
};

export const useCreateIngredientSupplier = () => {
  const mutation = useMutation((data: CreateIngredientOrgData) => {
    return IngredientOrgRepository.postCreateIngredient(data);
  });
  return mutation;
};

export const useUpdateCharacteristicIngredientSupplier = () => {
  const mutation = useMutation((data: CreateIngredientOrgData) => {
    return IngredientOrgRepository.putCreateIngredient(data);
  });
  return mutation;
};