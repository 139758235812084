import { useSession } from "../../_shared/context";
import { CharacteristicIn, CreateIngredientOrgData, IngredientCharacteristic, IngredientSpecs } from "../../network/interfaces/ingredient.interfaces";
import { Button, Form, Input, Modal, notification, Select } from "antd";
import { FieldArray, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { IngredientFormValues } from "./add-ingredient/add-ingredient";
import styles from './add-ingredient/add-ingredient.module.less';
import AddCharacteristicsTable from "./add-ingredient/add-characteristic-table";
import { useUpdateCharacteristicIngredientSupplier } from "../../network/services/ingredient-org.service";

const IngredientSchema = Yup.object().shape({
  characteristics: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      type: Yup.string().required('Type is required'),
      value: Yup.number().typeError('Value must be a number').required('Value is required'),
      unit: Yup.string().required('Unit is required'),
    })
  ),
});

export const ModifyCharacteristicsModal = (
  {
    setShowAddCharacteristicsModal,
    showAddCharacteristicsModal,
    refetchIngredients
  }:
    {
      setShowAddCharacteristicsModal: React.Dispatch<React.SetStateAction<any>>;
      showAddCharacteristicsModal: any;
      refetchIngredients: () => void;
    }
) => {

  const [initialValues, setInitialValues] = useState<any>({ characteristics: showAddCharacteristicsModal.Characteristic });

  const { user } = useSession();
  const updateCharacteristicIngredientSupplier = useUpdateCharacteristicIngredientSupplier();

  const UpdateFormikFields = ({ newData }: any) => {
    const { setValues } = useFormikContext(); // Get Formik's context

    useEffect(() => {
      if (newData) {
        console.log(newData);
        setValues(newData); // Update Formik when API returns data
      }
    }, [newData, setValues]);

    return null; // This component only updates Formik; no UI needed
  };


  const handleSubmit = (
    values: IngredientFormValues,
    actions: FormikHelpers<IngredientFormValues>
  ) => {
    console.log('Ingredient values:', values);

    const characteristicsToSend: CharacteristicIn[] = [];
    values?.characteristics?.map((ch) => {
      if (!ch.isNew) {

        const chIn: CharacteristicIn = {
          description: '',
          unit: ch.unit,
          name: ch.name,
          value: ch.value!,
          lowerBound: ch.range?.lowerBound!,
          upperBound: ch.range?.upperBound!,
          type: ch.type,
          threshold: ch.threshold,
        }
        characteristicsToSend.push(chIn);
      }
    })

    const data: CreateIngredientOrgData = {
      id: showAddCharacteristicsModal.id,
      organizationId: user?.organizationId!,
      characteristics: characteristicsToSend,
      functionalRole: values.functionalRole,
      type: values.type,
      category: values.category,
      unit: values.unit,
      description: values.description,
      price: Number(values.price),
      name: values.ingredientName,
      supplier: values.supplier,
    }

    updateCharacteristicIngredientSupplier.mutate(data, {
      onSuccess: (respnse) => {
        if (respnse.data.status) {
          setShowAddCharacteristicsModal(undefined);
          refetchIngredients();
          notification.success({ message: "Characteristic created successfully" })
        }
      },
      onError: (response) => {
        notification.error({ message: "Something went wrong" })
      }
    })

    actions.setSubmitting(false);
  };

  return <Modal open={true} width={1200} footer={<></>}>
    <Formik
      initialValues={initialValues}
      validationSchema={IngredientSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setTouched,
      }) => {
        return (

          <Form layout="vertical"
            onFinish={() => {
              handleSubmit();
              setTouched({
                characteristics: values.characteristics.map(() => ({
                  name: true,
                  type: true,
                  value: true,
                  unit: true,
                })),
              }, true);
            }}
            className={styles.formContainer}>
            <UpdateFormikFields newData={initialValues}></UpdateFormikFields>

            {/* Ingredient Characteristics */}
            <h3>Ingredient Characteristics</h3>
            <FieldArray name="characteristics">
              {({ push, remove }) => (
                <AddCharacteristicsTable
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setValues={setValues}
                  push={push}
                  remove={remove}
                />
              )}
            </FieldArray>

            {/* Actions */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
              <Button onClick={() => { setShowAddCharacteristicsModal(false); }}>Cancel</Button>
              <Button type="primary" className={styles.button} htmlType="submit" loading={isSubmitting}>
                Save Characteristics
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  </Modal>
}