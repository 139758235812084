import React, { useEffect, useState } from "react";
import { Table, Card, Input, Button, Tag, Tooltip } from "antd";
import { AppstoreOutlined, UnorderedListOutlined, PlusOutlined, ExportOutlined, FileOutlined } from "@ant-design/icons";
//@ts-ignore
import styles from "./ingredient-library.module.less";
import { useNavigate } from "react-router-dom";
import { useGetIngredientsByOrg } from "../../network/services/ingredient-org.service";
import { useSession } from "../../_shared/context";
import { CharacteristicIn, variableTypeLabels } from "../../network/interfaces/ingredient.interfaces";
import { ProjectsContainingIngredientList } from "./projects-containing-ingredient-list";
import { IngredientComparisonTable } from "./compare-ingredient/compare-ingredient";
import { IngredientDetailsModal } from "./ingredient-details-modal";
import { VariableType } from "@prisma/client";
import { Ingredient } from "__generated__/globalTypes";
import { ProjectsContainingIngredient } from "../../network/interfaces/project.interfaces";
import { ModifyCharacteristicsModal } from "./modify-characteristics-modal";

const { Search } = Input;

const IngredientLibrary: React.FC = () => {
  const [view, setView] = useState("table");
  const { user } = useSession();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isComparing, setIsComparing] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [sortField, setSortField] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<string | undefined>(undefined);
  const [showAddCharacteristicsModal, setShowAddCharacteristicsModal] = useState(false);

  const {
    data: ingredientsData,
    isError,
    isLoading: isIngredientsLoading,
    isSuccess,
    refetch: refetchIngridients,
  } = useGetIngredientsByOrg({
    organizationId: user?.organizationId!,
    page,
    pageSize,
    sortField,
    sortOrder,
    searchTerm
  });

  useEffect(() => {
    if (ingredientsData?.data?.result) {
      const processedData = ingredientsData.data.result.map((i: any) => {
        if (i.projectsContainingIngredient && i.projectsContainingIngredient.length > 0) {
          const min = Math.min(...i.projectsContainingIngredient.map((p: ProjectsContainingIngredient) => p.lowerLimit));
          const max = Math.max(...i.projectsContainingIngredient.map((p: ProjectsContainingIngredient) => p.upperLimit));
          return { ...i, valueRanges: `${min} - ${max}` };
        } else {
          if (i.type && ((i.lowerLimit && i.upperLimit) || i.value)) {
            return { ...i, valueRanges: i.type === VariableType.NUMERIC ? `${i.lowerLimit} - ${i.upperLimit}` : i.value };
          } else {
            return { ...i, valueRanges: "N/A" };
          }

        }
      });

      setData(processedData);
      setTotal(ingredientsData.data?.total);
    }
  }, [ingredientsData?.data?.result]);

  const columns = [
    {
      title: "Code",
      key: "externalCode",
      dataIndex: "externalCode",
      render: (text: string) => text || "-"
    },
    {
      title: "Name",
      key: "ingredient",
      render: (record: { ingredient: Ingredient; description: string }) => (
        <div>
          <div>{record.ingredient.name}</div>
          <Tooltip title={record.description}>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200, color: 'gray' }}>
              {record.description}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Supplier",
      dataIndex: ["supplierOrganization", "supplier", "name"],
      key: "supplier"
    },
    {
      title: "Functional role",
      dataIndex: ["functionalRole"],
      key: "functionalRole"
    },
    {
      title: "Category",
      dataIndex: ["category"],
      key: "category",
      render: (text: string) => text || "-"
    },
    {
      title: "Cost",
      key: "price",
      render: (item: { price: number, unit: string }) => item.price ? `${item.unit} ${item.price}` : "N/A",

    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: VariableType) => <Tag>{variableTypeLabels[type]}</Tag>,
    },
    {
      title: "Value/Ranges",
      dataIndex: "valueRanges",
      key: "valueRanges",
      render: (text: string) => (
        <span>{text}</span>),
    },
    {
      title: "Projects",
      dataIndex: ["projectsContainingIngredient"],
      key: "projects",
      render: (projects: ProjectsContainingIngredient[]) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'baseline' }}>
          {projects.map((project, index) => (
            index < 2 ?
              <Tag
                color="blue"
                style={{ cursor: 'pointer' }}
                onClick={() => { navigate(`/project/${project.id}/experiments`) }}
              >
                {project.key} <ExportOutlined />
              </Tag>
              : undefined
          ))}
        </div>
      )
    },
    {
      title: "Characteristics",
      key: "characteristics",
      render: (record: any) => (
        record.Characteristic.length > 0 ?
          <Button onClick={(e) => {
            e.stopPropagation();
            setShowAddCharacteristicsModal(record);
          }}>
            <FileOutlined />View Characteristics
          </Button>
          :
          <Button onClick={(e) => {
            e.stopPropagation();
            setShowAddCharacteristicsModal(record);
          }}>
            <FileOutlined />Add Characteristics
          </Button>
      )
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      render: (text: string) => (
        <span>{new Date(text).toLocaleDateString()}</span>),
      sorter: () => handleSort('createdAt'),
      sortDirections: ['ascend', 'descend'],

    }
  ]

  const handlePage = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    refetchIngridients();
  };

  const handleSort = (sorter: string) => {
    setSortField(sorter);
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  useEffect(() => {
    if (searchTerm || sortOrder) {
      setPage(1);
      setPageSize(10);
      refetchIngridients()
    }
  }, [searchTerm, sortOrder])


  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Ingredient Library</h2>

        <Button icon={<PlusOutlined />} type="primary" className={styles.button} onClick={() => { navigate('/ingredient/add') }}>Add New Ingredient</Button>

      </div>
      <div>
        <div className={styles.actions}>
          <Search
            placeholder="Search ingredients by name or characteristic..."
            style={{ width: 400 }}
            onChange={(e) => e.target.value.length > 2 ? setSearchTerm(e.target.value) : setSearchTerm('')}
          />
          <div className={styles.toggleContainer}>
            <div
              className={`${styles.toggleButton}  ${view === "table" ? styles.active : ""}`}
              onClick={() => setView("table")}
            >
              <UnorderedListOutlined />
            </div>
            <div
              className={`${styles.toggleButton} ${view === "grid" ? styles.active : ""}`}
              onClick={() => setView("grid")}
            >
              <AppstoreOutlined />
            </div>

          </div>
        </div>

        <Button type="primary" disabled={selectedItems.length < 2} className={styles.button} onClick={() => setIsComparing(true)} style={{ marginBottom: "16px" }}>
          Compare Selected ({selectedItems.length})
        </Button>
        {selectedItems.length > 1 &&
          <Button type="link" onClick={() => { setSelectedItems([]); setSelectedRowKeys([]); setIsComparing(false) }} style={{ marginBottom: "16px", color: "red" }}>
            Clear
          </Button>
        }
        {view === "grid" ? (
          <div className={styles.grid}>
            {data.map((item: any) => (
              <Card
                key={item.key}
                title={item.ingredient.name}
                className={`${styles.card} ${selectedItems.find(i => i.id === item.id) ? styles.selected : ""}`}
                onClick={() => {
                  setSelectedItems((prev) => {
                    const exists = prev.find(i => i.id === item.id);
                    return exists ? prev.filter(i => i.id !== item.id) : [...prev, item];
                  });
                }}
              >
                <p><strong>Supplier:</strong> {item.supplierOrganization.supplier.name}</p>
                <p><strong>Supplier Description:</strong> {item.supplierOrganization.description}</p>
                <p><strong>Cost:</strong> {item.price || "N/A"} </p>
                <div className={styles.characteristics}>
                  {item.Characteristic.map((char: CharacteristicIn) => (
                    <Tag key={char.name} className={styles.tag}>{char.name}</Tag>
                  ))}
                </div>
                <ProjectsContainingIngredientList item={item} />
              </Card>
            ))}
          </div>
        ) : (
          <Table
            //@ts-ignore
            columns={columns}

            dataSource={data}
            pagination={{
              total,
              onChange: handlePage,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
            }}
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              preserveSelectedRowKeys: true, // this will ensure persistence across pagination
              onChange: (newSelectedRowKeys, selectedRows) => {
                setSelectedRowKeys(newSelectedRowKeys);
                setSelectedItems(selectedRows);
              },
            }}

            onRow={(record) => ({
              onClick: () => setSelectedRecord(record),
            })}
          />
        )}
      </div>
      <IngredientDetailsModal selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
      {isComparing && (
        <IngredientComparisonTable ingredients={selectedItems} open={isComparing} onClose={() => setIsComparing(false)} />
      )}
      {showAddCharacteristicsModal &&
        <ModifyCharacteristicsModal setShowAddCharacteristicsModal={setShowAddCharacteristicsModal} showAddCharacteristicsModal={showAddCharacteristicsModal} refetchIngredients={refetchIngridients} />
      }
    </div>

  );
};

export default IngredientLibrary;
