import { ProjectSetupSteps } from '../../../../components/exploration/constants';
import { StepFlag } from '../../../../_shared/style';
import React from 'react';
import './project-setup-stepper-styles.css';

export const ProjectSetupStepper = ({
  activeStep,
}: {
  activeStep?: ProjectSetupSteps;
}) => {
  return (
    <div id="project-setup-stepper">
      <ul id="steps">
        <li
          className={`step ${
            activeStep == ProjectSetupSteps.PROJECT_INFO ? 'active' : ''
          }`}
        >
          {/* <span className="stepper-indicator"></span> */}
          <span className="step-flag">
            <StepFlag />
          </span>
          Start
          <div className="stepper-separator"></div>
        </li>
        <li
          className={`step ${
            activeStep == ProjectSetupSteps.UPLOAD_DATA ? 'active' : ''
          }`}
        >
          Upload Data
          <div className="stepper-separator"></div>
        </li>
        <li
          className={`step ${
            activeStep == ProjectSetupSteps.INPUT_OUTCOMES ||
            activeStep == ProjectSetupSteps.EDIT_RAW_DATA ||
            activeStep == ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES
              ? 'active'
              : ''
          }`}
        >
          Inputs & Outcomes
          <div className="stepper-separator"></div>
        </li>
        <li
          className={`step ${
            activeStep == ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS
              ? 'active'
              : ''
          }`}
        >
          Non-negotiable constraints
        </li>
      </ul>
    </div>
  );
};
