import React, { useEffect, useRef, useState } from 'react';
import { IRawDataAggregated } from '../RawDataAggregated.interfaces';
import { Button, message, notification, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ProjectInputOutcomeTypeDescription } from '../../../../../pages/project-setup/project-setup-constants';
import { ProjectRawDataAggregatedRow } from '../project-raw-data-aggregated-row/project-raw-data-aggregated-row';

import './project-raw-data-aggregated-table.less';
import { useRawDataContext } from '../../../../../_shared/context/raw-data-context';
import {
  useDeleteRaw,
  useGetRawData,
  useGetReferenceFormulation,
  usePutRawData,
} from '../../../../../network/services/rawdata.service';
import { useSession } from '../../../../../_shared/context';
import { useLocation } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { useTranslation } from 'react-i18next';
import DroppedArea from '../../../../../_shared/components/dropped-area/dropped-area';
import { useDroppable } from '@dnd-kit/core';
import { useDragDropContext } from '../../../../../_shared/context/drag-context';
import { DropDownOptions } from '../../../../../components/library/library';
import { ProjectType } from '@prisma/client';
import { inputAndOutputId } from '../../../../../_shared/utils/util';

interface ProjectRawDataAggregatedTableProps {
  enableNextStep: (enable: boolean) => void;
  proj_id?: string;
  projectHasData?: boolean;
  edit?: boolean;
  setNoDataFlowStarted: (enable: boolean) => void;
  saveAllInputsAndOutcomesOnEdit: boolean;
  setSaveAllInputsAndOutcomesOnEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const exampleRawData: IRawDataAggregated = {
  id: '0',
  variable: 'Hard Red Spring Flour',
  typeOfVariable: '',
  dataType: 'NUMERIC',
  min: 0,
  max: 2,
  unit: '%/gram',
  category: 'Flour',
  descriptiveFunctionalRole:
    'Powdered emulsifier for improving texture and stability in sauces.',
  cost: 0.21,
  costUnit: 'kg',
  lowerBound: 0,
  upperBound: 2,
  priorityOutcome: 1,
  addedManually: true,
};

export const emptyRawData: IRawDataAggregated = {
  id: '',
  variable: '',
  typeOfVariable: '',
  dataType: 'NUMERIC',
  min: 0,
  max: 0,
  unit: '',
  category: '',
  descriptiveFunctionalRole: '',
  cost: 0,
  costUnit: '',
  lowerBound: 0,
  upperBound: 0,
  priorityOutcome: 1,
  addedManually: false,
};

export const ProjectRawDataAggregatedTable: React.FC<ProjectRawDataAggregatedTableProps> = ({
  enableNextStep,
  proj_id,
  projectHasData,
  edit,
  setNoDataFlowStarted,
  saveAllInputsAndOutcomesOnEdit,
  setSaveAllInputsAndOutcomesOnEdit
}) => {
  const { user, currentProject } = useSession();

  const urlPath = useLocation();

  const { data, isSuccess, isLoading, refetch: refetchRawData } = useGetRawData(
    {
      projectId: proj_id!,
      organizationId: user?.organizationId ?? '',
    }
  );
  const { t } = useTranslation();
  const editRawData = usePutRawData();
  const deleteRawData = useDeleteRaw();
  const { addFilterIngredients, removeFilterIngredients, addFilterProcessingConditions, addFilterOutcomes } = useDragDropContext();

  const {
    setNamesToDeleteFromDataAggregated,
    namesToDeleteFromDataAggregated,
    rawRemoved,
  } = useRawDataContext();
  const { ingredientDroppedItem, clearIngredientDroppedItem } = useDragDropContext();
  const { active, isOver, setNodeRef, over } = useDroppable({
    id: inputAndOutputId,
  });

  useEffect(() => {
    if (ingredientDroppedItem) {
      addNewColumn(ingredientDroppedItem)
    }
  }, [ingredientDroppedItem]);


  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const [dataAggrgatedList, setDataAggregatedList] = useState<
    IRawDataAggregated[]
  >([]);

  const [referenceFormulation, setReferenceFormulation] = useState<any>();

  const { data: referenceFormulationData } = useGetReferenceFormulation({
    organizationId: currentProject?.organizationId ?? '',
    projectId: currentProject?.id ?? '',
    enableQuery:
      currentProject?.ProjectType === ProjectType.PRESERVE_LABEL_LOWER_COST,
  });

  useEffect(() => {
    if (referenceFormulationData) {
      setReferenceFormulation(referenceFormulationData.data);
    }
  }, [referenceFormulationData]);

  const TypeDescription = ProjectInputOutcomeTypeDescription;

  const addNewColumn = (
    droppedItem?: any
  ) => {
    const element = { ...emptyRawData };

    if (projectHasData) {
      Object.assign(element, {
        addedManually: true,
        lowerBound: 0,
        upperBound: 100,
        typeOfVariable: 'EXISTING_INGREDIENT',
        priorityOutcome: undefined,
      });
    }

    if (droppedItem?.name) {
      element.variable = droppedItem?.name;
    }

    switch (droppedItem?.type) {
      case DropDownOptions.ProcessingConditions:
        const basePropertiesProcessing = {
          typeOfVariable: 'PROCESSING',
          descriptiveFunctionalRole: droppedItem.ingredient.descriptiveFunctionalRole,
          cost: droppedItem.ingredient.price ?? Number(droppedItem.ingredient.price),
          unit: droppedItem.ingredient.unit ?? Number(droppedItem.ingredient.unit),
          costUnit: droppedItem.ingredient.costUnit ?? droppedItem.ingredient.costUnit,
          category: droppedItem.ingredient.category.name ?? droppedItem.ingredient.category.name,
          dataType: droppedItem.ingredient.type ?? droppedItem.ingredient.type,
        };


        if (droppedItem.ingredient.type === 'ORDINAL' || droppedItem.ingredient.type === 'CATEGORICAL') {
          Object.assign(element, {
            ...basePropertiesProcessing,
            valuesDetected: droppedItem?.ingredient?.values.join(','),
          });
        } else {

          Object.assign(element, {
            ...basePropertiesProcessing,
            lowerBound: droppedItem.ingredient.lowerLimit ?? Number(droppedItem.ingredient.lowerLimit),
            upperBound: droppedItem.ingredient.upperLimit ?? Number(droppedItem.ingredient.upperLimit),
            max: droppedItem.ingredient.upperLimit ?? Number(droppedItem.ingredient.upperLimit),
            min: droppedItem.ingredient.lowerLimit ?? Number(droppedItem.ingredient.lowerLimit),
          });
        }
        break;

      case DropDownOptions.Ingredients:
        const basePropertiesIngredient = {
          typeOfVariable: 'EXISTING_INGREDIENT',
          descriptiveFunctionalRole: droppedItem.ingredient.descriptiveFunctionalRole,
          cost: droppedItem.ingredient.price ?? Number(droppedItem.ingredient.price),
          unit: droppedItem.ingredient.unit ?? Number(droppedItem.ingredient.unit),
          costUnit: droppedItem.ingredient.costUnit ?? droppedItem.ingredient.costUnit,
          category: droppedItem.ingredient.category.name ?? droppedItem.ingredient.category.name,
          dataType: droppedItem.ingredient.type ?? droppedItem.ingredient.type,
        };

        if (droppedItem.ingredient.type === 'ORDINAL' || droppedItem.ingredient.type === 'CATEGORICAL') {
          Object.assign(element, {
            ...basePropertiesIngredient,
            valuesDetected: droppedItem?.ingredient?.values.join(','),
          });
        } else {

          Object.assign(element, {
            ...basePropertiesIngredient,
            lowerBound: droppedItem.ingredient.lowerLimit ?? Number(droppedItem.ingredient.lowerLimit),
            upperBound: droppedItem.ingredient.upperLimit ?? Number(droppedItem.ingredient.upperLimit),
            max: droppedItem.ingredient.upperLimit ?? Number(droppedItem.ingredient.upperLimit),
            min: droppedItem.ingredient.lowerLimit ?? Number(droppedItem.ingredient.lowerLimit),
          });
        }
        break;

      case DropDownOptions.Outcomes:
        element.typeOfVariable = getOutcomeType(droppedItem.category);
        element.lowerBound = droppedItem.lower ?? Number(droppedItem.lower);
        element.upperBound = droppedItem.upper ?? Number(droppedItem.upper);
        element.max = droppedItem.upper ?? Number(droppedItem.upper);
        element.min = droppedItem.lower ?? Number(droppedItem.lower);
        break;
    }
    if (droppedItem?.name) {
      element.priorityOutcome = 0;
      element.id = dataAggrgatedList.length.toString();

      setDataAggregatedList([...dataAggrgatedList, element]);

      applyFilters(droppedItem.type, element.variable);
      setNoDataFlowStarted(true);
      editColumn(element)
      clearIngredientDroppedItem()
    }
    else {
      element.id = dataAggrgatedList.length.toString();
      setDataAggregatedList([...dataAggrgatedList, element]);

      applyFilters(droppedItem.type, element.variable);
      setNoDataFlowStarted(true);
    }

  };

  const getOutcomeType = (category: string) => {
    switch (category) {
      case 'Analytical':
        return 'ANALYTICAL_OUTCOME';
      case 'Sensory':
        return 'SENSORY_OUTCOME';
      default:
        return 'CONSUMER_OUTCOME';
    }
  };

  const applyFilters = (type: string, variable: string) => {
    switch (type) {
      case DropDownOptions.Ingredients:
        addFilterIngredients(variable, true);
        break;
      case DropDownOptions.ProcessingConditions:
        addFilterProcessingConditions(variable, true);
        break;
      case DropDownOptions.Outcomes:
        addFilterOutcomes(variable, true);
        break;
    }
  };

  useEffect(() => {
    if (data !== undefined && Array.isArray(data)) {
      const aggragetedFromServer: IRawDataAggregated[] = data.map(
        (o: any) =>
        ({
          ...o,
          values: o.valuesDetected,
        } as IRawDataAggregated)
      );

      setDataAggregatedList(aggragetedFromServer);
      setNamesToDeleteFromDataAggregated(aggragetedFromServer.filter(rw => rw.isSelectedForRemove).map(rw => rw.variable))
      const allVariables = getAllVariables(aggragetedFromServer)
      addFilterIngredients(allVariables, false)
      addFilterProcessingConditions(allVariables, false)
      addFilterOutcomes(allVariables, false)
    }
  }, [data]);

  const getAllVariables = (dataArray: any) => {
    return dataArray
      .map((item: any) => item.variable) // Extract 'variable' field
      .filter((variable: any) => variable !== null && variable !== undefined); // Remove null/undefined
  };

  useEffect(() => {
    if (rawRemoved) {
      refetchRawData();
    }
  }, [rawRemoved]);

  useEffect(() => {
    if (dataAggrgatedList && dataAggrgatedList.length) {
      let countIngredients = 0;
      let countOutcomes = 0;
      let countFillerIngredients = 0;
      let lowerAndUpperSame = false;
      let hasMissingTypeOfVariable = false;
      dataAggrgatedList.map(rd => {
        if (!rd.typeOfVariable) {
          hasMissingTypeOfVariable = true;
        }
        if (rd.typeOfVariable === 'FILLER_INGREDIENT') {
          countFillerIngredients++;
        } else if (rd.typeOfVariable?.includes('INGREDIENT')) {
          countIngredients++;
        } else if (rd.typeOfVariable?.includes('OUTCOME')) {
          countOutcomes++;
        }
        if (!lowerAndUpperSame && rd.dataType == 'NUMERIC')
          lowerAndUpperSame = rd.lowerBound >= rd.upperBound;
      });
      enableNextStep(
        countIngredients > 0 &&
        countOutcomes > 0 &&
        countFillerIngredients > 0 &&
        !lowerAndUpperSame &&
        !hasMissingTypeOfVariable
      );
      setNoDataFlowStarted(true);
    }
  }, [dataAggrgatedList]);

  const deleteColumn = (_id: string, variable: string) => {
    setIsRequestInProgress(true);
    deleteRawData.mutate(
      {
        projectId: proj_id ?? '',
        organizationId: user?.organizationId ?? '',
        outputId: _id,
      },
      {
        onSuccess: async resp => {
          notification.success({
            message: t(
              'project-setup.page.inputsOutcomes.messages.variableDeletedSuccessfully'
            ),
          });
          removeFilterIngredients(variable);
          await refetchRawData();
        },
        onSettled: () => {
          setIsRequestInProgress(false);
        },
      }
    );

    // const copy = dataAggrgatedList.filter(rd => rd.id !== _id);
    // setDataAggregatedList(copy);
  };

  const editColumn = (editedData: IRawDataAggregated) => {
    setIsRequestInProgress(true);
    editRawData.mutate(
      {
        projectId: proj_id ?? '',
        organizationId: user?.organizationId ?? '',
        outputId: editedData.id,
        output: {
          variable: editedData.variable.trim(),
          typeOfVariable: editedData.typeOfVariable,
          dataType: editedData.dataType,
          min: Number(editedData.min),
          max: Number(editedData.max),
          category: editedData.category,
          descriptiveFunctionalRole: editedData.descriptiveFunctionalRole,
          cost: editedData.cost,
          costUnit: editedData.costUnit,
          lowerBound: Number(editedData.lowerBound),
          upperBound: Number(editedData.upperBound),
          priorityOutcome: editedData.priorityOutcome ?? 0,
          unit: editedData.unit,
          valuesDetected: editedData.valuesDetected,
          isSelectedForRemove: editedData.isSelectedForRemove,
          addedManually: editedData.addedManually,

          adjustedIngredient: editedData.adjustedIngredient,
          goalPriority: editedData.goalPriority,
          adjustedRange: editedData.adjustedRange,

          adjustedMinusRange: editedData.adjustedMinusRange,
          adjustedPlusRange: editedData.adjustedPlusRange,
        },
      },
      {
        onSuccess: async response => {
          if (isNaN(Number(editedData.id))) {
            notification.success({
              message: t(
                'project-setup.page.inputsOutcomes.messages.variableEditedSuccessfully'
              ),
            });
          } else {
            notification.success({
              message: t(
                'project-setup.page.inputsOutcomes.messages.variableAddedSuccessfully'
              ),
            });
          }

          await refetchRawData();
        },
        onSettled: () => {
          setIsRequestInProgress(false);
        },
      }
    );

    const rawDataAggregatedEdited = dataAggrgatedList.map(rd =>
      rd.id === editedData.id ? editedData : rd
    );

    setDataAggregatedList(rawDataAggregatedEdited);
  };

  return (
    <div id="project-inputs-raw">
      {(isRequestInProgress || isLoading) && (
        <BlockUi
          tag="div"
          blocking={isRequestInProgress}
          loader={<Spin></Spin>}
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      {
        <div>
          <div id="project-inputs-table" ref={setNodeRef} className={active?.data.current.target === inputAndOutputId ? 'is-over' : ''}>
            <div id="header" className="row-table">
              {currentProject?.originalProjectId &&
                !urlPath.pathname.includes('/inspect/') && (
                  <div
                    className="cost"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        'Select variables to exclude from the project. The model will still be trained using the full dataset.'
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                )}
              <div
                className="category"
                style={{
                  position: 'sticky',
                  left: 0,
                  // zIndex: 9999,
                  backgroundColor: '#fbfbfb',
                }}
              >
                Variables
              </div>
              {currentProject?.ProjectType ===
                ProjectType.PRESERVE_LABEL_LOWER_COST && (
                  <>
                    <div className="cost">Reference Value</div>
                    <div className="name">Flexibility Range (%)</div>
                  </>
                )}
              <div className="name">Type of variable</div>
              <div className="test-condition">
                Data Type
                <Tooltip placement="right" title={TypeDescription}>
                  <InfoCircleOutlined style={{ marginLeft: '0.5rem' }} />
                </Tooltip>
              </div>
              {/* <div className="cost">Min value observed</div>
              <div className="cost">Max value observed</div> */}
              <div className="test-condition">Min - Max Observed values</div>
              <div className="unit">Unit</div>
              <div className="test-condition">Category</div>
              <div className="test-condition">Descriptive Functional Role</div>
              <div className="cost">Cost</div>
              <div className="cost">Cost Unit</div>
              {/* <div className="cost">Lower bound</div>
              <div className="cost">Upper bound</div> */}
              <div className="test-condition">Values</div>
              <div className="test-condition">Priority of outcome</div>
            </div>
            {!dataAggrgatedList.length && <p className="drop-placeholder">Drop items here</p>}
            {dataAggrgatedList?.map((data: IRawDataAggregated) => (
              <ProjectRawDataAggregatedRow
                edit={edit}
                data={data}
                key={data.id}
                addMode={!data.variable ? true : false}
                editDataListener={editColumn}
                deleteDataListener={deleteColumn}
                projectHasData={projectHasData}
                referenceFormulation={referenceFormulation}
                saveAllInputsAndOutcomesOnEdit={saveAllInputsAndOutcomesOnEdit}
                setSaveAllInputsAndOutcomesOnEdit={setSaveAllInputsAndOutcomesOnEdit}
              />
            ))}
          </div>
          {
            currentProject?.ProjectType !== ProjectType.PRESERVE_LABEL_LOWER_COST && (
              <div className="add-inputs-box">
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => addNewColumn()}
                  className="add-input-button"
                >
                  Add new
                </Button>
              </div>
            )
          }

        </div >
      }
    </div >
  );
};
