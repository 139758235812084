import { DropDownOptions } from '../../components/library/library';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';

interface DroppedItem {
  id: string;
  name: string;
  type: DropDownOptions;
  descriptiveFunctionalRole?: string
  category?: string;
}

interface DragDropContextType {
  draggedItem: any;
  dropTargetId: string | null;
  ingredientDroppedItem: DroppedItem;
  objectivesDroppedItem: DroppedItem;
  filterIngredients: any;
  filterProcessingConditions: any;
  filterOutcomes: any;
  droppedProcessingConditions: DroppedItem;

  setDraggedItem: (data: any) => void;
  setDropTargetId: (id: string | null) => void;
  resetState: () => void;
  removeDroppedItem: (id: string) => void;
  addIngredientDroppedItems: (item: any) => void;
  addObjectivesDroppedItems: (item: any) => void;
  addFilterIngredients: (item: any, clear?: boolean) => void;
  removeFilterIngredients: (item: any) => void;
  addFilterProcessingConditions: (item: any, clear?: boolean) => void;
  addFilterOutcomes: (item: any, clear?: boolean) => void;
  clearIngredientDroppedItem: () => void
}

const DragDropContext = createContext<DragDropContextType | undefined>(
  undefined
);

export const DragDropProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [dropTargetId, setDropTargetId] = useState<string | null>(null);


  const [ingredientDroppedItem, setIngredientDroppedItem] = useState<DroppedItem>();
  const [objectivesDroppedItem, setObjectivesDroppedItem] = useState<DroppedItem>();
  const [filterIngredients, setFilterIngredients] = useState<string[]>([]);

  const [filterProcessingConditions, setFilterProcessingConditions] = useState<string[]>([]);
  const [filterOutcomes, setFilterOutcomes] = useState<string[]>([]);


  const addIngredientDroppedItems = useCallback((item: any) => {
    setIngredientDroppedItem(item);
  }, []);
  const clearIngredientDroppedItem = useCallback(() => {
    setIngredientDroppedItem(undefined);
  }, []);
  const addObjectivesDroppedItems = useCallback((item: any) => {
    setObjectivesDroppedItem(item);
  }, []);

  const resetState = useCallback(() => {
    setDraggedItem(null);
    setDropTargetId(null);
  }, []);

  const addFilterIngredients = useCallback(
    (item: any, clear: boolean = false) => {
      if (clear) {
        setFilterIngredients(prevItems => [...prevItems, item]);
      } else {
        setFilterIngredients(item);
      }
    },
    []
  );

  const removeFilterIngredients = useCallback((itemToRemove: any) => {
    setFilterIngredients(prevItems =>
      prevItems.filter(item => item !== itemToRemove)
    );
  }, []);



  const addFilterProcessingConditions = useCallback(
    (item: any, clear: boolean = false) => {
      if (clear) {
        setFilterProcessingConditions(prevItems => [...prevItems, item]);
      } else {
        setFilterProcessingConditions(item);
      }
    },
    []
  );



  const addFilterOutcomes = useCallback((item: any, clear: boolean = false) => {
    if (clear) {
      setFilterOutcomes((prevItems) => [...prevItems, item])
    } else {
      setFilterOutcomes(item);
    }
  }, []);


  return (
    <DragDropContext.Provider
      value={{
        draggedItem,
        dropTargetId,
        ingredientDroppedItem,
        objectivesDroppedItem,
        filterIngredients,
        filterProcessingConditions,
        filterOutcomes,
        setDraggedItem,
        setDropTargetId,
        resetState,
        addIngredientDroppedItems,
        addObjectivesDroppedItems,
        addFilterIngredients,
        removeFilterIngredients,
        addFilterProcessingConditions,
        addFilterOutcomes,
        clearIngredientDroppedItem
      }}
    >
      {children}
    </DragDropContext.Provider>
  );
};

export const useDragDropContext = () => {
  const context = useContext(DragDropContext);
  if (!context) {
    throw new Error(
      'useDragDropContext must be used within a DragDropProvider'
    );
  }
  return context;
};
