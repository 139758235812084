import React from "react";
import { Modal, Table, Tag } from "antd";
import { CharacteristicIn, variableTypeLabels } from "../../../network/interfaces/ingredient.interfaces";
import './compare-ingredient.less';
import { VariableType } from "@prisma/client";

interface IngredientComparisonTableProps {
  ingredients: any[];
  open: boolean;
  onClose: () => void;
}

export const IngredientComparisonTable: React.FC<IngredientComparisonTableProps> = ({ ingredients, open, onClose }) => {
  const staticRows = [
    {
      key: "name",
      label: "Name",
      render: (item: any) => {
        return (<div>
          <div style={{ fontWeight: "bold" }}>{item.ingredient.name}</div>
          <div style={{ color: "#8c8c8c", fontSize: 12 }}>{item.description}</div>
        </div>)
      }
    },
    {
      key: "supplier",
      label: "Supplier",
      render: (item: any) => item.supplierOrganization.supplier.name,
    },
    {
      key: "externalCode",
      label: "Code",
      render: (item: any) => item.externalCode || "N/A",
    },
    {
      key: "type",
      label: "Type",
      render: (item: { type: VariableType }) => <Tag>{variableTypeLabels[item.type]}</Tag>,
    },
    {
      key: "valueRanges",
      label: "Value/Ranges",
      render: (item: any) => item.valueRanges,
    },
    {
      key: "category",
      label: "Category",
      render: (item: any) => item.category,
    },
    {
      key: "price",
      label: "Cost",
      render: (item: any) => item.price ?
        `${item.price}${item.unit ? ' ' + item.unit : ''}` :
        "N/A",
    },
    {
      key: "functionalRole",
      label: "Functional Role",
      render: (item: any) => item.functionalRole,
    },
  ];
  const allCharacteristics = Array.from(
    new Set(
      ingredients.flatMap((item) =>
        item.Characteristic?.map((char: CharacteristicIn) => char.name) || []
      )
    )
  );

  const getCharacteristicValue = (item: any, charName: string) => {
    const char = item.Characteristic?.find((c: CharacteristicIn) => c.name === charName);
    return char?.value ? `${char?.value} ${char?.unit || ""}` : "-";
  };

  const dataSource = [
    ...staticRows.map((row) => {
      const record: any = { key: row.key, attribute: row.label };
      ingredients.forEach((item) => {
        record[item.id] = row.render(item);
      });
      return record;
    }),
    ...allCharacteristics.map((charName) => {
      const record: any = { key: `char-${charName}`, attribute: charName };
      ingredients.forEach((item) => {
        record[item.id] = getCharacteristicValue(item, charName);
      });
      return record;
    }),
  ];

  const columns = [
    {
      title: staticRows[0].label,
      dataIndex: "attribute",
      key: "attribute",
      width: 200,
      render: (text: string) => <div style={{ fontWeight: "bold", color: "#8c8c8c", textAlign: "left" }}> {text}</div >,
    },
    ...ingredients.map((item) => ({
      title: (
        <div>
          <div style={{ fontWeight: "bold" }}>{item.ingredient.name}</div>
          <div style={{ color: "#8c8c8c", fontSize: 12 }}>{item.supplierOrganization.supplier.name}</div>
        </div>
      ),
      dataIndex: item.id,
      key: item.id,
      width: undefined
    })),
  ];

  return (
    <Modal open={open} onCancel={onClose} onOk={onClose} title="Compare Ingredients" width="768px" footer={null}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: true }}
        bordered={false}
        className="comparationTable"
        showHeader={false}
        rowClassName={(record, index) => index % 2 === 0 ? 'light-gray-row' : ''}
      />
    </Modal>
  );
};