import React, { useEffect, useState } from 'react';
import { Formik, FieldArray, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Button, Space, Table, notification, Select } from 'antd';
import UploadSpecificationModal from '../upload-ingredient-specs/upload-ingredient-specs';
//@ts-ignore
import styles from './add-ingredient.module.less';
import { UploadOutlined } from '@ant-design/icons';
import { CharacteristicIn, CreateIngredientOrgData, IngredientCharacteristic, IngredientSpecs, UploadIngredientSpec, variableTypeLabels } from '../../../network/interfaces/ingredient.interfaces';
import { useNavigate, useNavigation } from "react-router-dom";
import { useCreateIngredientSupplier } from '../../../network/services/ingredient-org.service';
import { useSession } from '../../../_shared/context';
import AddCharacteristicsTable from './add-characteristic-table';
import { VariableType } from '@prisma/client';

const IngredientSchema = Yup.object().shape({
  ingredientName: Yup.string().required('Ingredient Name is required'),
  supplier: Yup.string(),
  functionalRole: Yup.string(),
  price: Yup.number().required('Cost is required').typeError('Cost must be a number'),
  unit: Yup.string().required('Cost unit is required'),
  category: Yup.string(),
  type: Yup.string().required('Type is required'),
  externalCode: Yup.string().required('Code is required'),
  description: Yup.string(),
  value: Yup.string().when('type', {
    is: (val: VariableType) => val !== VariableType.NUMERIC,
    then: (schema) => schema.required('Value is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  lowerLimit: Yup.number().when('type', {
    is: VariableType.NUMERIC,
    then: (schema) => schema.required('Lower limit is required').typeError('Lower limit must be a number'),
    otherwise: (schema) => schema.notRequired(),
  }),
  upperLimit: Yup.number().when('type', {
    is: VariableType.NUMERIC,
    then: (schema) => schema.required('Upper limit is required').typeError('Upper limit must be a number'),
    otherwise: (schema) => schema.notRequired(),
  }),
  characteristics: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      type: Yup.string().required('Type is required'),
      value: Yup.number().typeError('Value must be a number').required('Value is required'),
      unit: Yup.string().required('Unit is required'),
    })
  ),
}).test('lower-upper-validation', `Lower limit should be low than upper limit`, function (values) {
  const { lowerLimit, upperLimit } = values;
  if (lowerLimit != null && upperLimit != null && lowerLimit > upperLimit) {
    return this.createError({
      path: 'lowerLimit',
      message: `Lower limit should be low than upper limit`,
    });
  }
  return true;
});

const UpdateFormikFields = ({ newData }: any) => {
  const { setValues } = useFormikContext(); // Get Formik's context

  useEffect(() => {
    if (newData) {
      console.log(newData);
      setValues(newData); // Update Formik when API returns data
    }
  }, [newData, setValues]);

  return null; // This component only updates Formik; no UI needed
};

const AddIngredient: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [briefId, setBriefId] = useState<string | undefined>();
  const navigate = useNavigate();
  const { user } = useSession();
  const createIngredientSupplier = useCreateIngredientSupplier();
  const [initialValues, setInitialValues] = useState<IngredientSpecs>({
    ingredientName: '',
    supplier: '',
    functionalRole: '',
    externalCode: '',
    type: undefined,
    category: '',
    unit: '',
    lowerLimit: undefined,
    upperLimit: undefined,
    value: '',
    description: '',
    price: undefined,
    characteristics: [] as IngredientCharacteristic[],
  });


  const handleSubmit = (
    values: IngredientSpecs,
    actions: FormikHelpers<IngredientSpecs>
  ) => {
    console.log('Ingredient values:', values);

    const characteristicsToSend: CharacteristicIn[] = [];
    values?.characteristics?.map((ch) => {
      if (!ch.isNew) {

        const chIn: CharacteristicIn = {
          description: '',
          unit: ch.unit,
          name: ch.name,
          value: ch.value!,
          lowerBound: ch.range?.lowerBound!,
          upperBound: ch.range?.upperBound!,
          type: ch.type,
          threshold: ch.threshold
        }
        characteristicsToSend.push(chIn);
      }
    })

    const data: CreateIngredientOrgData = {
      organizationId: user?.organizationId!,
      characteristics: characteristicsToSend,
      functionalRole: values.functionalRole,
      type: values.type!,
      upperLimit: values.upperLimit,
      lowerLimit: values.lowerLimit,
      value: values.value,
      externalCode: values.externalCode,
      category: values.category,
      unit: values.unit,
      description: values.description,
      price: Number(values.price),
      name: values.ingredientName,
      supplier: values.supplier,
    }
    createIngredientSupplier.mutate(data, {
      onSuccess: (respnse) => {
        if (respnse.data.status) {
          navigate('/ingredient/library');
          notification.success({ message: "Ingredient created successfully" })
        }
      },
      onError: (response) => {
        notification.error({ message: "Something went wrong" })
      }
    })
    actions.setSubmitting(false);
  };

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (data?: UploadIngredientSpec[]): void => {
    if (data && data.length) {
      const result = data[0];
      if (result.ingredientsSpec) {
        console.log(result.ingredientsSpec);
        setBriefId(result.id);
        setInitialValues(result.ingredientsSpec);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        {/* Header */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ fontWeight: 'bold' }}>Add New Ingredient</h2>
          <Button type="default" className={styles.uploadButton} icon={<UploadOutlined />} onClick={handleOpenModal}>
            Upload Specification
          </Button>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          validationSchema={IngredientSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setValues,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            setTouched,
          }) => {
            const navigate = useNavigate();
            return (

              <Form layout="vertical" onFinish={() => {
                handleSubmit();
                const allFieldsTouched = {
                  ingredientName: true,
                  supplier: true,
                  functionalRole: true,
                  price: true,
                  description: true,
                  lowerLimit: true,
                  upperLimit: true,
                  value: true,
                  unit: true,
                  category: true,
                  type: true,
                  externalCode: true,
                  characteristics: values.characteristics.map(() => ({
                    name: true,
                    type: true,
                    value: true,
                    unit: true,
                  })),
                };
                setTouched(allFieldsTouched, true);
              }}
                className={styles.formContainer}>
                <UpdateFormikFields newData={initialValues}></UpdateFormikFields>
                <div className={styles.inputRow}>
                  <Form.Item
                    label="Code *"
                    validateStatus={touched.externalCode && errors.externalCode ? 'error' : ''}
                    help={touched.externalCode && errors.externalCode ? errors.externalCode : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="externalCode" value={values.externalCode} placeholder="Enter an ingredient code" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>
                  <Form.Item
                    label="Ingredient Name *"

                    validateStatus={touched.ingredientName && errors.ingredientName ? 'error' : ''}
                    help={touched.ingredientName && errors.ingredientName ? errors.ingredientName : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="ingredientName" value={values.ingredientName} placeholder="Enter ingredient name" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>

                  <Form.Item
                    label="Supplier"
                    validateStatus={touched.supplier && errors.supplier ? 'error' : ''}
                    help={touched.supplier && errors.supplier ? errors.supplier : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="supplier" value={values.supplier} placeholder="Enter supplier name" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>

                </div>
                <div className={styles.inputRow}>
                  {/* Functional Role */}
                  <Form.Item
                    label="Functional Role"
                    validateStatus={touched.functionalRole && errors.functionalRole ? 'error' : ''}
                    help={touched.functionalRole && errors.functionalRole ? errors.functionalRole : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="functionalRole" placeholder="Enter functional role" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>

                  <Form.Item
                    label="Cost *"
                    validateStatus={touched.price && errors.price ? 'error' : ''}
                    help={touched.price && errors.price ? errors.price : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="price" placeholder="Enter Cost" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>

                  <Form.Item
                    label="Cost unit *"
                    validateStatus={touched.unit && errors.unit ? 'error' : ''}
                    help={touched.unit && errors.unit ? errors.unit : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="unit" placeholder="Enter Unit" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>
                </div>
                <div className={styles.inputRow}>
                  <Form.Item
                    label="Type *"
                    validateStatus={touched.type && errors.type ? 'error' : ''}
                    help={touched.type && errors.type ? errors.type : null}
                    className={styles.inputHalf}>
                    <Select className={styles.customAntInput} placeholder="Select Type *"
                      onChange={(value) => setValues({ ...values, type: value })}
                      onBlur={handleBlur}
                      options={[
                        { value: VariableType.NUMERIC, label: variableTypeLabels[VariableType.NUMERIC] },
                        { value: VariableType.CATEGORICAL, label: variableTypeLabels[VariableType.CATEGORICAL] },
                        { value: VariableType.ORDINAL, label: variableTypeLabels[VariableType.ORDINAL] },
                      ]}>

                    </Select>
                  </Form.Item>

                  {values.type === VariableType.NUMERIC && <div className={styles.inputHalf}>
                    <Form.Item
                      label="Lower Limit *"
                      validateStatus={touched.lowerLimit && errors.lowerLimit ? 'error' : ''}
                      help={touched.lowerLimit && errors.lowerLimit ? errors.lowerLimit : null}
                      className={styles.inputHalf}>
                      <Input className={styles.customAntInput} name="lowerLimit" placeholder="Enter lower limit" onChange={handleChange} onBlur={handleBlur} />
                    </Form.Item>
                    <Form.Item
                      label="Upper Limit *"
                      validateStatus={touched.upperLimit && errors.upperLimit ? 'error' : ''}
                      help={touched.category && errors.upperLimit ? errors.upperLimit : null}
                      className={styles.inputHalf}>
                      <Input className={styles.customAntInput} name="upperLimit" placeholder="Enter upper limit" onChange={handleChange} onBlur={handleBlur} />
                    </Form.Item>
                  </div>}
                  {values.type !== VariableType.NUMERIC && <Form.Item
                    label="Value *"
                    validateStatus={touched.value && errors.value ? 'error' : ''}
                    help={touched.value && errors.value ? errors.value : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="value" placeholder="Enter Value " onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>}
                  <Form.Item
                    label="Category"
                    validateStatus={touched.category && errors.category ? 'error' : ''}
                    help={touched.category && errors.category ? errors.category : null}
                    className={styles.inputHalf}>
                    <Input className={styles.customAntInput} name="category" placeholder="Enter Category*" onChange={handleChange} onBlur={handleBlur} />
                  </Form.Item>
                </div>
                {/* Description */}
                <Form.Item label="Description">
                  <Input.TextArea className={styles.customAntInput} name="description" placeholder="Enter ingredient description" onChange={handleChange} onBlur={handleBlur} rows={4} />
                </Form.Item>

                {/* Ingredient Characteristics */}
                <h3>Ingredient Characteristics</h3>
                <FieldArray name="characteristics">
                  {({ push, remove }) => (
                    <AddCharacteristicsTable
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setValues={setValues}
                      push={push}
                      remove={remove}
                    />
                  )}
                </FieldArray>



                {/* Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                  <Button onClick={() => { navigate('/ingredient/library') }}>Cancel</Button>
                  <Button type="primary" className={styles.button} htmlType="submit" loading={isSubmitting}>
                    Save Ingredient
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>

        {/* Upload Modal */}
        <UploadSpecificationModal visible={isModalOpen} onClose={handleCloseModal} />
      </div>

    </div>
  );
};

export default AddIngredient;