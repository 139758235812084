import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Typography, Select, Tooltip, AutoComplete, ProgressProps, notification, Tag, Popover, InputNumber, Switch, Modal, SelectProps, Drawer } from "antd";
import { ConstraintInputType, ConstraintType, CostOptimizationOption, VariableType } from "../../../../../__generated__/globalTypes";
import { validateConstraint } from "../../../components/workspaces/adaptive-learning/design-validation";
import { IngredientType, useIngredients } from "../../../_shared/hooks";
import { useScenarioDetail } from "../../../_shared/context/scenario-detail-context";
import { ConstraintReason } from "@prisma/client";
import { QuestionCircleOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import * as nearley from "nearley";
import { IngredientFromGroup, IngredientGroupData, IngredientGroupDataIn } from "../../../components/project/ingredients-group/ingredients-group.interfaces";
import { useSession } from "../../../_shared/context";
import { useCreateIngredientgroup, useGetIngredientsGroup } from "../../../network/services/ingredient-group.service";
import { ConstraintQuickGuidePopover } from "./constraint-quick-guide-popover";
import { useFormulations } from "../../../_shared/context/formulations-context";
import { limitDecimals } from "../../../_shared/utils/util";
const grammar = require("../../../_shared/utils/constraint-parser/constraint-parser.js")

const { TextArea } = Input;
const { Text, Title } = Typography;

const conicColors: ProgressProps['strokeColor'] = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#FF0000',
};

interface editedConstraint extends ConstraintInputType {
  isEdited: boolean;
}

const capitalizeEachWord = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

export const ConstraintEditor = (
  {
    editingConstraint,
    setEditingConstraint
  }:
    {
      editingConstraint: ConstraintInputType | undefined;
      setEditingConstraint: React.Dispatch<React.SetStateAction<undefined>>;
    }
) => {

  const { ingredients, ingredientByName } = useIngredients();
  const { user, currentProject, addIngredientGroup, ingredientCompositionList } = useSession();
  const {
    minTestedFormulationsCostScore,
    maxTestedFormulationsCostScore,
  } = useFormulations();
  const {
    removeConstraint,
    constraints,
    saveConstraints,
    constraintIndex,
    constraint,
    costOptimizationOption,
    nteCost,
    setCostOptimizationOption,
    setNteCost,
    editConstraint
  } = useScenarioDetail();
  const createGroupMutation = useCreateIngredientgroup();
  const {
    data: ingredientsGroup,
    isSuccess: ingredientGroupIsSuccess,
    refetch
  } = useGetIngredientsGroup({
    projectId: currentProject?.id,
    organizationId: user?.organizationId,
  });

  const [query, setQuery] = useState("");
  const [comment, setComment] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [reason, setReason] = useState<ConstraintReason | undefined>();
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedIngredientGroupId, setSelectedIngredientGroupId] = useState<string | undefined>(undefined);
  const [selectedIngredientCompositionId, setSelectedIngredientCompositionId] = useState<string | undefined>(undefined);
  const [groupOrCompositionOriginalName, setGroupOrCompositionOriginalName] = useState<string | undefined>(undefined);
  const [groupOrCompositionChangedName, setGroupOrCompositionChangedName] = useState<string | undefined>(undefined);
  const [ingredientsInQuery, setIngredientsInQuery] = useState<string[]>([]);
  const [nominalOrdinalVariableSelected, setNominalOrdinalVariableSelected] = useState<IngredientType | undefined>();
  const [nominalOrdinalOptions, setNominalOrdinalOptions] = useState<SelectProps['options']>();
  const [nominalOrdinalOptionsSelected, setNominalOrdinalOptionsSelected] = useState<string>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (nominalOrdinalVariableSelected) {
      const options: SelectProps['options'] = []
      nominalOrdinalVariableSelected.values.map((value) => options.push({ label: value, value }))
      setNominalOrdinalOptions(options)
    }
  }, [nominalOrdinalVariableSelected])

  useEffect(() => {
    setIsValid(query !== undefined && reason !== undefined);
  }, [query, reason]);

  useEffect(() => {
    if (editingConstraint && editingConstraint.name && editingConstraint.reason) {
      setQuery(editingConstraint.name)
      setReason(editingConstraint.reason)
    }
    if (editingConstraint?.ingredientGroupId)
      setSelectedIngredientGroupId(editingConstraint.ingredientGroupId)
    if (editingConstraint?.ingredientCompositionId)
      setSelectedIngredientGroupId(editingConstraint.ingredientCompositionId)
  }, [editingConstraint]);

  useEffect(() => {
    if (!ingredientByName || ingredientByName.size === 0) {
      return;
    }
    const ingredientNames = Array.from(ingredientByName.keys())
    let labels = ingredientNames.map(
      (ingredient) => ({
        value: `@${capitalizeEachWord(ingredient).replace(/\s/g, '')}`
      })
    );

    Object.keys(ingredientsGroup).forEach((ig) => {
      labels.push({ value: `@${ingredientsGroup[ig][0].group.name}` })
    })

    ingredientCompositionList.map((i) => {
      labels.push({ value: `@${i.name}` })
    })

    setOptions(labels);
  }, [ingredientByName, ingredientsGroup]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (validationMessage && query.length > 1)
      setValidationMessage(undefined);
    let value = e.target.value;
    setQuery(value);

    const newIngredientsInQuery = Array.from(ingredientByName.keys())
      .filter((ingredient) => (
        value.includes(capitalizeEachWord(ingredient).replace(/\s/g, '').concat(" ")) || value.includes(capitalizeEachWord(ingredient).replace(/\s/g, '').concat(",")))
        && ingredientByName.get(ingredient)?.type === VariableType.NUMERIC);

    setIngredientsInQuery(newIngredientsInQuery);
    const lastAtIndex = value.lastIndexOf("@");

    if (lastAtIndex !== -1) {
      const substringAfterAt = value.substring(lastAtIndex + 1).trim();

      let currentVariable = ingredientByName.get(substringAfterAt);
      if (currentVariable?.type !== "NUMERIC") {
        setNominalOrdinalVariableSelected(currentVariable);
      }

      // Filtering items to show in @ dropdown
      // Ingredients
      const filteredOptions = Array.from(ingredientByName.keys())
        .filter((ingredient) =>
          ingredient.toLowerCase().includes(substringAfterAt.toLowerCase()) && !newIngredientsInQuery.includes(ingredient)
        )
        .map((ingredient) => ({ value: `@${capitalizeEachWord(ingredient).replace(/\s/g, '')}` }));
      // Ingredients Groups
      Object.values(ingredientsGroup)
        .map((groupArray: any[]) => groupArray[0])
        .filter((element: any) =>
          element.group.name.toLowerCase().includes(substringAfterAt.toLowerCase())
        )
        .forEach((element: any) => {
          filteredOptions.push({
            value: `@${element.group.name}`,
          });
        });
      // Ingredients Composition
      ingredientCompositionList
        .filter((ic) =>
          ic.name.toLowerCase().includes(substringAfterAt.toLowerCase())
        )
        .map((ic) => filteredOptions.push({ value: `@${ic.name}` }))


      // Check if input match a group or a composition
      // group
      const matchedGroupId = Object.keys(ingredientsGroup).find(
        (ig) => ingredientsGroup[ig][0].group.name.toLowerCase().trim() === substringAfterAt.toLowerCase().trim()
      );
      if (matchedGroupId) {
        setSelectedIngredientGroupId(matchedGroupId);
        setGroupOrCompositionOriginalName(ingredientsGroup[matchedGroupId][0].group.name);
        const groupNameToShow = `@${ingredientsGroup[matchedGroupId][0].group.name.replace(/\s/g, '')}`;
        setGroupOrCompositionChangedName(groupNameToShow.slice(1));
        value = value.substring(0, lastAtIndex) + groupNameToShow + value.substring(lastAtIndex + substringAfterAt.length + 1);
        setQuery(value);
      }

      // composition
      const matchedComposition = ingredientCompositionList.find((ic) => ic.name.toLowerCase() === substringAfterAt.toLowerCase());
      if (matchedComposition) {
        setSelectedIngredientCompositionId(matchedComposition.id);
        const compositionNameToShow = `@${matchedComposition.name.replace(/\s/g, '')}`;
        value = value.substring(0, lastAtIndex) + compositionNameToShow + value.substring(lastAtIndex + substringAfterAt.length + 1);
        setQuery(value);
      }

      setOptions(filteredOptions);
      setAutocompleteVisible(filteredOptions.length > 0);
      setCursorPosition(lastAtIndex);
    } else {
      setAutocompleteVisible(false);
    }
  };

  const handleCommentChange = (e: { target: { value: any; }; }) => {
    let value = e.target.value
    setComment(value);
  };

  const handleSelect = (selectedIngredient: string) => {
    if (textareaRef.current) {
      const beforeAt = query.substring(0, cursorPosition);

      const afterAtMatch = query.substring(cursorPosition).match(/(\s|[\+\-\*\/<>=!])/);
      const afterAtIndex = afterAtMatch ? cursorPosition + afterAtMatch.index! : query.length;
      const afterAt = query.substring(afterAtIndex);

      const matchedGroup = Object.keys(ingredientsGroup).find(
        (ig) => ingredientsGroup[ig][0].group.name === selectedIngredient.slice(1)
      );
      if (matchedGroup) {
        setSelectedIngredientGroupId(matchedGroup);
        setGroupOrCompositionOriginalName(selectedIngredient.slice(1));
        selectedIngredient = selectedIngredient.replace(/\s/g, '');
        setGroupOrCompositionChangedName(selectedIngredient.slice(1));
      }

      const matchedComposition = ingredientCompositionList.find((ic) => ic.name.toLowerCase() === selectedIngredient.slice(1).toLowerCase());
      if (matchedComposition) {
        setSelectedIngredientCompositionId(matchedComposition.id);
        setGroupOrCompositionOriginalName(matchedComposition.name);
        selectedIngredient = `@${matchedComposition.name.replace(/\s/g, '')}`;
        setGroupOrCompositionChangedName(selectedIngredient.slice(1));
      }

      const newQuery = `${beforeAt}${selectedIngredient} ${afterAt}`.trim();

      setQuery(newQuery);
      textareaRef.current.defaultValue = newQuery;
      setAutocompleteVisible(false);
      if (!matchedComposition && !matchedGroup && ingredientByName.get(selectedIngredient)?.type === VariableType.NUMERIC)
        setIngredientsInQuery([...ingredientsInQuery, selectedIngredient.slice(1)])
    }
  };

  const handleSaveConstraint = async () => {
    if (validateQuery()) {
      const parsedConstraint = parseQuery(query) as ConstraintInputType;
      if (parsedConstraint) {
        let validationResponse = validateConstraint(parsedConstraint, ingredients);
        if (validationResponse.isValid) {
          let constraintsToSave;
          if (parsedConstraint.id) {
            // We don't want to acidently pass the old version of the constraint with the same ID
            let otherConstraints = constraints.filter(
              constraint => constraint.id !== parsedConstraint.id
            );
            constraintsToSave = [...otherConstraints, parsedConstraint];
          } else {
            constraintsToSave = [...constraints, parsedConstraint];
          }

          const constraint = await saveConstraints(constraintsToSave);
          setValidationMessage(undefined);
        } else {
          setValidationMessage(validationResponse.description);
        }
      }
      setQuery("");
      setComment("");
      setIngredientsInQuery([]);
      setSelectedIngredientGroupId(undefined);
      setSelectedIngredientCompositionId(undefined);
      setGroupOrCompositionOriginalName(undefined);
      setGroupOrCompositionChangedName(undefined);
      setReason(undefined);
    } else {
      setValidationMessage("Invalid query format");
    }
  };

  const handleCancelEditingConstraint = () => {
    setEditingConstraint(undefined);
    setQuery("");
    setReason(undefined)
  };

  const handleInputCostChange = (value: number | undefined) => {
    if (value) {
      setNteCost(value);
      setCostOptimizationOption(CostOptimizationOption.LIMIT);
    } else {
      setCostOptimizationOption(CostOptimizationOption.MINIMIZE)
      setNteCost(0)
    }
  };

  const handleNominalOrdinalApply = () => {
    let newQuery = query.trim().concat(` = '${nominalOrdinalOptionsSelected}'`);
    setQuery(newQuery);
    setNominalOrdinalVariableSelected(undefined)
  }

  function parseQuery(query: string) {
    let constraint: ConstraintInputType = {
      id: '',
      constraintType: ConstraintType.EQUALITY,
      lowerBounds: null,
      upperBounds: null,
      ingredientCompositionId: null,
      coefficients: [],
      values: [],
      variables: [],
    };
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
    try {
      parser.feed(query);
      const res = parser.results[0][0][0];
      let queryToSave;
      if (groupOrCompositionOriginalName && groupOrCompositionChangedName)
        queryToSave = query.replace(groupOrCompositionChangedName, groupOrCompositionOriginalName)
      if (res.type === 'simple') {
        if (selectedIngredientGroupId || selectedIngredientCompositionId) {
          if (res.operator[0] === '=') {
            constraint.constraintType = ConstraintType.EQUALITY;
            constraint.values = [{ name: groupOrCompositionOriginalName, value: parseFloat(res.value) }];
          } else if (res.operator[0] === '<' || res.operator[0] === '<=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, operator: "range", value: 1 }];
            constraint.lowerBounds = 0
            constraint.upperBounds = res.value ? parseFloat(res.value) : undefined;
          } else if (res.operator[0] === '>' || res.operator[0] === '>=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, operator: "range", value: 1 }];
            constraint.lowerBounds = res.value ? parseFloat(res.value) : undefined;
            constraint.upperBounds = 100
          }
          constraint.name = queryToSave ? queryToSave : query;
          selectedIngredientGroupId ? constraint.ingredientGroupId = selectedIngredientGroupId : constraint.ingredientCompositionId = selectedIngredientCompositionId;
        } else {
          if (res.operator[0] === '=') {
            constraint.constraintType = ConstraintType.EQUALITY;
            constraint.values = [{ name: res.ingredient.trim(), value: parseFloat(res.value) }];
          } else if (res.operator[0] === '<' || res.operator[0] === '<=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
            constraint.lowerBounds = findLowestValue(res.ingredient.trim());
            constraint.upperBounds = res.value ? parseFloat(res.value) : undefined;
          } else if (res.operator[0] === '>' || res.operator[0] === '>=') {
            constraint.constraintType = ConstraintType.RANGE;
            constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
            constraint.lowerBounds = res.value ? parseFloat(res.value) : undefined;
            constraint.upperBounds = findHighestValue(res.ingredient.trim());
          }
          constraint.name = queryToSave ? queryToSave : query;
        }
      } else if (res.type === 'range') {
        if (selectedIngredientGroupId || selectedIngredientCompositionId) {
          if (((res.lower.operator[0] === '<' || res.lower.operator[0] === '<=') && (res.upper.operator[0] === '<' || res.upper.operator[0] === '<=')) ||
            ((res.lower.operator[0] === '>' || res.lower.operator[0] === '>=') && (res.upper.operator[0] === '>' || res.upper.operator[0] === '>='))) {
            constraint.coefficients = [{ name: groupOrCompositionOriginalName, value: 1, operator: "range" }];
            constraint.lowerBounds = res.lower.value < res.upper.value ? res.lower.value : res.upper.value;
            constraint.upperBounds = res.upper.value > res.lower.value ? res.upper.value : res.lower.value;
            constraint.constraintType = ConstraintType.RANGE;
            selectedIngredientGroupId ? constraint.ingredientGroupId = selectedIngredientGroupId : constraint.ingredientCompositionId = selectedIngredientCompositionId;
          }
        } else if (((res.lower.operator[0] === '<' || res.lower.operator[0] === '<=') && (res.upper.operator[0] === '<' || res.upper.operator[0] === '<=')) ||
          ((res.lower.operator[0] === '>' || res.lower.operator[0] === '>=') && (res.upper.operator[0] === '>' || res.upper.operator[0] === '>='))) {
          constraint.constraintType = ConstraintType.RANGE;
          constraint.coefficients = [{ name: res.ingredient.trim(), value: 1 }];
          constraint.lowerBounds = res.lower.value;
          constraint.upperBounds = res.upper.value;
        }
        constraint.name = queryToSave ? queryToSave : query;
      } else if (res.type === 'assignment') {
        if (res.ingredients[0][0].factor) {
          let ingredientGroupData: IngredientGroupData = { name: res.group.trim(), lowerBound: 0, upperBound: 100, ingredient: [], groupSum: false, creationQuery: queryToSave ?? query };
          res.ingredients[0].map((i: { ingredient: string | null | undefined; factor: any; }) => {
            const ingredient = ingredients.find((ing) => ing.ingredient.labelName === i.ingredient)
            if (ingredient) {
              const ingredientToGroup: IngredientFromGroup = { percentage: i.factor, ingredientName: ingredient?.ingredient.name!, ingredientId: ingredient?.ingredient.id! }
              ingredientGroupData.ingredient.push(ingredientToGroup);
            }
          })
          addGroup(ingredientGroupData);
          return undefined;
        } else {
          let ingredientGroupData: IngredientGroupData = { name: res.group, lowerBound: 0, upperBound: 100, ingredient: [], groupSum: false, creationQuery: queryToSave ?? query };
          res.ingredients[0].map((i: string) => {
            const ingredient = ingredients.find((ing) => ing.ingredient.labelName === i)
            const ingredientToGroup: IngredientFromGroup = { percentage: 0, ingredientName: ingredient?.ingredient.name!, ingredientId: ingredient?.ingredient.id! }
            ingredientGroupData.ingredient.push(ingredientToGroup);
          })
          addGroup(ingredientGroupData);
          return undefined;
        }
      } else if (res.type === 'count') {
        if (selectedIngredientGroupId) {
          constraint.constraintType = ConstraintType.COUNT;
          let variables: string[] = []
          ingredientsGroup[selectedIngredientGroupId].map((item: { ingredient: { name: string; }; }) => variables.push(item.ingredient.name))
          constraint.variables = variables;
          constraint.lowerBounds = res.constraints.min ?? 1;
          constraint.upperBounds = res.constraints.max ?? ingredientsGroup[selectedIngredientGroupId].length;
          constraint.coefficients = [{ name: query, operator: 'some' }]
          constraint.ingredientGroupId = selectedIngredientGroupId;
        }
      } else if (res.type === 'nominal') {
        if (!selectedIngredientGroupId && !selectedIngredientCompositionId) {
          constraint.constraintType = ConstraintType.EQUALITY;
          constraint.values = [{ name: res.field, value: res.value }];
          constraint.name = query;
        }
      }

      constraint.reason = reason ?? undefined;
      constraint.comment = comment ?? undefined;
      constraint.name = queryToSave ? queryToSave : query;
      return constraint;
    } catch (error) {
      console.error("Error parsing query:", error);
      return null;
    }
  };

  const validateQuery = () => {
    let isQueryValid = false;

    const regexes = [
      /^@[\w\.\(\)\%\/\&\#-]+\s*(<=|>=|<|>|=)\s*([+-]?\d+(?:\.\d+)?)$/,
      /^([+-]?\d+(?:\.\d+)?)\s*(<=|>=|<|>)\s*@[\w\.\(\)\%\/\&\#-]+\s*(<=|>=|<|>)\s*([+-]?\d+(?:\.\d+)?)$/,
      /^@[\w\.\(\)\%\/\&\#-]+\s*=\s*(@[\w\.\(\)\%\/\&\#-]+(?:,\s*@[\w\.\(\)\%\/\&\#-]+)*)$/,
      /^@[\w\.\(\)\%\/\&\#-]+\s*=\s*(@[\w\.\(\)\%\/\&\#-]+\s*\*\s*[+-]?\d+(?:\.\d+)?(?:\s*,\s*@[\w\.\(\)\%\/\&\#-]+\s*\*\s*[+-]?\d+(?:\.\d+)?)*)$/,
      /^@[\w\.\(\)\%\/\&\#-]+\s*,\s*min:\s*([+-]?\d+(?:\.\d+)?)$/,
      /^@[\w\.\(\)\%\/\&\#-]+\s*,\s*max:\s*([+-]?\d+(?:\.\d+)?)$/,
      /^@[\w\.\(\)\%\/\&\#-]+\s*,\s*min:\s*([+-]?\d+(?:\.\d+)?),\s*max:\s*([+-]?\d+(?:\.\d+)?)$/,
      /^@[\w-]+\s*=\s*'([^']+)'$/,
      /^@[\w-]+\s*=\s*(@[\w-]+\s*\*\s*[+-]?\d+(?:\.\d+)?(?:\s*,\s*@[\w-]+\s*\*\s*[+-]?\d+(?:\.\d+)?)*)$/
    ];

    for (let i = 0; i < regexes.length; i++) {
      if (regexes[i].test(query)) {
        isQueryValid = true;
        return isQueryValid;
      }
    }

    return isQueryValid;
  };


  const findLowestValue = (ingredient: string): number => {
    const found = ingredients.find((i) => i.ingredient.name === ingredient)
    if (found && found.lowerLimit)
      return found.lowerLimit
    else
      return 0
  };

  const findHighestValue = (ingredient: string): number => {
    const found = ingredients.find((i) => i.ingredient.name === ingredient)
    if (found && found.upperLimit)
      return found.upperLimit
    else
      return 100
  };

  const saveEditedConstraint = () => {
    if (constraintIndex !== undefined && constraint) {
      const parsedConstraint: editedConstraint = parseQuery(query) as editedConstraint;
      const clonedConstraints = [...constraints];
      if (parsedConstraint) {
        parsedConstraint.isEdited = true;
        clonedConstraints[constraintIndex] = { ...parsedConstraint, id: constraint.id };
        saveConstraints(clonedConstraints);
      }

      setQuery('');
      setReason(undefined);
      setEditingConstraint(undefined);
    }
  };

  const addGroup = (e: IngredientGroupData) => {
    const payload: IngredientGroupDataIn = {
      group_name: e.name,
      creator_id: user?.id,
      ingredientsList: e.ingredient.map(i => ({
        ingredient_id: i.ingredientId,
        percentage: i.percentage ?? 1,
      })),
      lower_bound: e.lowerBound,
      upper_bound: e.upperBound,
      groupSum: e.groupSum,
      group_creation_query: e.creationQuery
    };
    createGroupMutation.mutate(
      {
        projectId: currentProject?.id ?? '',
        organizationId: user?.organizationId ?? '',
        ingredientGroup: payload,
      },
      {
        onSuccess: async (response: any) => {
          console.log(response);
          const newgroup = response.data;
          addIngredientGroup({
            name: newgroup.name,
            id: newgroup.id,
            lowerBound: newgroup.lowerBound,
            upperBound: newgroup.upperBound,
            ingredient: e.ingredient,
            groupSum: e.groupSum
          });
          notification.success({ message: `${newgroup.name} created successfully` })
          refetch()
        },
      }
    );
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
        Click here for a quick guide
        <QuestionCircleOutlined
          style={{ fontSize: 20, cursor: 'pointer' }}
          onClick={showDrawer}
        />
        <Drawer
          title={<Title level={5}>Constraints quick guide</Title>}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
          width={400}
          mask={false}
        >
          <ConstraintQuickGuidePopover />
        </Drawer>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", gap: 4, marginBottom: 12 }}>
        {ingredientsInQuery.map((i) => {
          const ingredient = ingredientByName.get(i);
          if (!ingredient) return null;
          return (
            <Tag color="#FF8080" key={i} style={{ borderRadius: 10 }}>
              {i}: {ingredient.lowerLimit} - {ingredient.upperLimit}
            </Tag>
          );
        })}
      </div>
      <div style={{ width: "100%" }}>
        {!nominalOrdinalVariableSelected &&
          <AutoComplete
            options={options}
            open={autocompleteVisible}
            value={query}
            onSelect={handleSelect}
            style={{
              left: 0,
              top: "100%",
              width: "100%",
              zIndex: 10,
            }}
          >
            <TextArea
              ref={textareaRef}
              value={query}
              onChange={handleQueryChange}
              placeholder="Type @ to insert an ingredient. Example: @Sugar <= 10"
            />
          </AutoComplete>}
        {nominalOrdinalVariableSelected &&
          <TextArea
            ref={textareaRef}
            value={query}
            onChange={handleQueryChange}
            placeholder="Type @ to insert an ingredient. Example: @Sugar <= 10"
          />}
      </div>
      {validationMessage && <Text type="danger">{validationMessage}</Text>}

      <div
        style={{ marginTop: 10 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 4,

          height: '2.5rem'
        }}
        >
          <div style={{ gap: 6, display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE}
              onChange={() => setCostOptimizationOption(
                costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE && !nteCost ?
                  CostOptimizationOption.MINIMIZE
                  : costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE && nteCost ?
                    CostOptimizationOption.LIMIT
                    : CostOptimizationOption.DO_NOT_OPTIMIZE)}
            />
            {costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE &&
              <>
                <InputNumber onChange={(value: number) => { handleInputCostChange(value) }} value={nteCost ? nteCost : undefined} placeholder="Max value" />
                <Text>{costOptimizationOption === CostOptimizationOption.MINIMIZE ? "Minimize cost" : `Limit cost to ${nteCost}`}</Text>
              </>
            }
            {costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE &&
              <Text>Don't optimize for cost</Text>
            }

          </div>

        </div>
        {typeof minTestedFormulationsCostScore === "number" &&
          typeof maxTestedFormulationsCostScore === "number" &&
          !isNaN(minTestedFormulationsCostScore) &&
          !isNaN(maxTestedFormulationsCostScore) &&
          minTestedFormulationsCostScore !== 99999999999 &&
          <Text type="secondary">
            {`Existing formulations in this project range from $${limitDecimals(
              minTestedFormulationsCostScore,
              2
            )} to $${limitDecimals(
              maxTestedFormulationsCostScore,
              2
            )} cost score. `}
          </Text>
        }
      </div>

      <div
        style={{ display: 'flex', gap: 6, alignItems: 'center', marginBottom: '10px', marginTop: 8, justifyContent: 'space-between' }}
      >
        <span style={{ alignItems: 'center' }}>Reason:</span>
        <Select
          style={{ width: '37vh' }}
          value={reason ?? undefined}
          options={[
            { value: ConstraintReason.SAFETY, label: 'Safety' },
            { value: ConstraintReason.REGULATORY, label: 'Regulatory' },
            { value: ConstraintReason.MANUFACTURING, label: 'Manufacturing' },
            { value: ConstraintReason.NUTRITIONAL, label: 'Nutritional' },
          ]}
          onChange={(value) => setReason(value as ConstraintReason)}
        />
      </div>

      <div
        style={{ display: 'flex', gap: 6, marginBottom: 10, alignItems: 'center', justifyContent: 'space-between' }}
      >
        <span style={{ alignItems: 'center' }}>Comment:</span>
        <Input
          value={comment}
          style={{ width: '37vh' }}
          onChange={handleCommentChange}
          placeholder="Insert comments about the constraint"
          maxLength={250}
        />
      </div>



      <Tooltip title={!isValid ? 'Invalid query format. Use @Ingredient followed by a constraint and remember to select a Reason' : undefined}>
        {!editingConstraint &&
          <Button type="primary" icon={<SaveOutlined />} block disabled={!isValid} onClick={() => handleSaveConstraint()}
            style={{
              backgroundColor: isValid ? "#ef4136" : undefined,
              borderColor: isValid ? "#ef4136" : undefined,
            }}
          >
            Save Constraint
          </Button>}
        {editingConstraint &&
          <div
            style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
          >
            <Button type="primary" icon={<StopOutlined />} block onClick={() => handleCancelEditingConstraint()}
              style={{
                backgroundColor: isValid ? "#F5F5F5" : undefined,
                borderColor: isValid ? "black" : undefined,
                color: "black"
              }}
            >
              Cancel
            </Button>
            <Button type="primary" icon={<SaveOutlined />} block disabled={!isValid} onClick={() => saveEditedConstraint()}
              style={{
                backgroundColor: isValid ? "#ef4136" : undefined,
                borderColor: isValid ? "#ef4136" : undefined,
              }}
            >
              Save Constraint
            </Button>
          </div>}
      </Tooltip>

      {nominalOrdinalVariableSelected &&
        <Modal
          title={`Select value for ${nominalOrdinalVariableSelected.ingredient.name}`}
          open={true}
          onCancel={() => setNominalOrdinalVariableSelected(undefined)}
          onOk={() => handleNominalOrdinalApply()}
          okText='Apply'
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
            <Text>@{nominalOrdinalVariableSelected.ingredient.name}</Text>
            <Select
              style={{ width: '60%' }}
              placeholder={`Value for ${nominalOrdinalVariableSelected.ingredient.name}`}
              options={nominalOrdinalOptions}
              value={nominalOrdinalOptionsSelected}
              onChange={(selectedValues) => setNominalOrdinalOptionsSelected(selectedValues)}
            />
          </div>
        </Modal>
      }
    </div>
  );
};