import { ExportOutlined } from "@ant-design/icons";
import { ProjectsContainingIngredient } from "../../network/interfaces/project.interfaces";
import { Card, Col, Modal, Row, Tag, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { variableTypeLabels } from "../../network/interfaces/ingredient.interfaces";
import { SupplierIngredientWithProjects } from "../../network/interfaces/supplier.interface";
import { VariableType } from "@prisma/client";

const { Text } = Typography;

export const IngredientDetailsModal = (
  {
    selectedRecord,
    setSelectedRecord
  }:
    {
      selectedRecord: SupplierIngredientWithProjects | undefined,
      setSelectedRecord: React.Dispatch<React.SetStateAction<any>>
    }
) => {
  const navigate = useNavigate()
  const [rangesValues, setRangesValues] = useState("N/A");

  useEffect(() => {
    if (selectedRecord) {
      console.log(selectedRecord);
      let text = ''
      if (selectedRecord.projectsContainingIngredient?.length) {
        text = `${Math.min(...selectedRecord.projectsContainingIngredient.map((p: ProjectsContainingIngredient) => p.lowerLimit))} - ${Math.max(...selectedRecord.projectsContainingIngredient.map((p: ProjectsContainingIngredient) => p.upperLimit))}`
      } else {
        if (selectedRecord.type && (selectedRecord.value || (selectedRecord.lowerLimit && selectedRecord.upperLimit))) {
          text = selectedRecord.value ? selectedRecord.value : `${selectedRecord.lowerLimit} - ${selectedRecord.upperLimit}`
        } else {
          text = 'N/A'
        }
      }
      setRangesValues(text);
    }
  }, [selectedRecord])

  return <Modal
    title={`${selectedRecord?.ingredient.name}`}
    open={selectedRecord !== undefined}
    onCancel={() => setSelectedRecord(undefined)}
    footer={null}
    width={600}
  >
    {selectedRecord && (
      <>
        <Card
          bordered={false}
          style={{ marginBottom: 16, background: "#f5f5f5" }}

        >
          <Row>
            <Col span={12}>
              <Text type="secondary">Standard Cost</Text>
              <br />
              <Text strong>
                {selectedRecord.price ? `${selectedRecord.unit} ${selectedRecord.price}` : "N/A"}
              </Text>
            </Col>
            <Col span={12}>
              <Text type="secondary">Standard Type</Text>
              <br />
              <Text strong>
                <Tag>{variableTypeLabels[selectedRecord.type]}</Tag>
              </Text>
            </Col>
            <Col span={12}>
              <Text type="secondary">Standard Value/Ranges</Text>
              <br />
              <Text strong>
                {rangesValues}
              </Text>
            </Col>

          </Row>
        </Card>
        {selectedRecord.projectsContainingIngredient.map((project: ProjectsContainingIngredient) => (
          <Card
            key={project.name}
            bordered={false}
            style={{ marginBottom: 16 }}
            bodyStyle={{ padding: 10 }}
          >
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                {project.name}
                <Tag color="blue" style={{ marginLeft: 15, cursor: 'pointer' }} onClick={() => { navigate(`/project/${project.id}/experiments`) }}>
                  {project.key} <ExportOutlined size={4} />
                </Tag>
              </Typography.Title>
            </Row>
            <Card style={{ marginTop: 8, background: "white" }}>
              <Row>
                <Col span={12}><Text strong>Cost</Text><br /><Text style={{ color: "#ff4d4f" }}>{project.price} USD/kg</Text></Col>
                <Col span={12}><Text strong>Range</Text><br /><Text style={{ color: "green" }}>{project.lowerLimit} - {project.upperLimit}</Text></Col>
              </Row>
            </Card>
          </Card>
        ))}
      </>
    )}
  </Modal>
}