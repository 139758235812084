import { useMutation, useQuery } from 'react-query';
import { IngredientRepository } from '../repositories/ingredient.repository';
import {
  CreateIngredientOut,
  GetIngredientOut,
} from '../interfaces/ingredient.interfaces';

export const useCreateIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.postCreateIngredient(data);
  });
  return mutation;
};

export const useGetIngredients = (
  data: GetIngredientOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getIngredients`, data.organizationId, data.projectId],
    IngredientRepository.getIngredients,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useUpdateIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.patchCreateIngredient(data);
  });
  return mutation;
};

export const useDeleteIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.deleteCreateIngredient(data);
  });
  return mutation;
};

export const useGetIngredientsByOrg = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
  excludedNames: string[];
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getIngredientsLibraryByOrg`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
      data.excludedNames,
    ],
    IngredientRepository.getIngredientsByOrg,
    {
      enabled: !!data.organizationId, // Or any other condition to enable the query
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};

export const useGetProcessingConditionsByOrg = (data: {
  organizationId: string;
  page: number;
  limit: number;
  searchTerm: string;
  excludedNames: string[];
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  const query = useQuery(
    [
      `getProcessingConditionsByOrg`,
      data.organizationId,
      data.page,
      data.limit,
      data.searchTerm,
      data.excludedNames,
    ],
    IngredientRepository.getProcessingConditionsByOrg,
    {
      enabled: !!data.organizationId,
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes (adjust as needed)
      cacheTime: 1000 * 60 * 10, // Cache lasts for 10 minutes
      keepPreviousData: true, // Keep previous data while refetching
      select: response => response.data,
    }
  );
  return {
    ...query,
  };
};
