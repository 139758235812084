import React, { useState } from "react";
import { Card, Input, Tag, Typography } from "antd";

//@ts-ignore
import styles from "./ingredient-library.module.less";

const { Search } = Input;

const { Text } = Typography;

const generateColor = (index: number) => {
  const colors = ["#ff4d4f", "#fa8c16", "#fadb14", "#52c41a", "#1890ff", "#722ed1"];
  return colors[index % colors.length];
};


export const ProjectsContainingIngredientList = ({ item }: any) => {
  const [selectedProject, setSelectedProject] = useState<{ name: string; lowerLimit: number | null; upperLimit: number | null; price: number | null; } | null>(null);

  const handleProjectClick = (project: { name: string; lowerLimit: number | null; upperLimit: number | null; price: number | null; }) => {
    setSelectedProject((prev) => (prev?.name === project.name ? null : project));
  };

  return (
    <div>
      {item.projectsContainingIngredient.length > 0 ? (
        <>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginBottom: "8px" }}>
            {item.projectsContainingIngredient.map((project: { name: string; lowerLimit: number | null; upperLimit: number | null; price: number | null; }, index: number) => {
              const isSelected = selectedProject?.name === project.name;
              const color = generateColor(index);
              return (
                <Tag
                  key={project.name}
                  className={styles.projectTag}
                  onClick={() => handleProjectClick(project)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: isSelected ? color : "transparent",
                    color: isSelected ? "#fff" : color,
                    border: isSelected ? "none" : `1px solid ${color}`,
                  }}
                >
                  {project.name}
                </Tag>
              );
            })}
          </div>
          {selectedProject && (
            <Card
              type="inner"
              style={{
                borderRadius: "8px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                padding: "6px 10px",
                marginTop: "8px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Text strong>{selectedProject.name}</Text>
                <div style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                  <Tag>Cost: {selectedProject.price}</Tag>
                  <Tag color="blue">{selectedProject.lowerLimit !== null ? `Lower: ${selectedProject.lowerLimit}` : "Lower: -"}</Tag>
                  <Tag color="red">{selectedProject.upperLimit !== null ? `Upper: ${selectedProject.upperLimit}` : "Upper: -"}</Tag>
                </div>
              </div>
            </Card>
          )}
        </>
      ) : (
        <Text type="secondary">No projects available</Text>
      )}
    </div>
  );
};