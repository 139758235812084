import React, { useEffect, useState } from 'react';

export const InitiativesLoader = ({
  isFinished,
  creationDate,
}: {
  isFinished: boolean;
  creationDate: string;
}) => {
  const [progress, setProgress] = useState<number>(0);

  const calculateInitialProgress = (): number => {
    const now = new Date();
    const creationTime = new Date(creationDate);
    const timeSpentInMinutes = (now.getTime() - creationTime.getTime()) / 60000;

    return Math.min(timeSpentInMinutes * 90, 90);
  };

  useEffect(() => {
    setProgress(calculateInitialProgress());

    let interval: NodeJS.Timeout | undefined;

    if (progress < 90) {
      interval = setInterval(() => {
        setProgress(prev => {
          const nextValue = prev + 2;
          return nextValue >= 90 ? 90 : Number(nextValue.toFixed(0));
        });
      }, 300);
    } else if (progress >= 90 && !isFinished) {
      interval = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 1 : prev));
      }, 500);
    }

    if (isFinished && progress >= 90) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isFinished, creationDate]);

  return (
    <div>
      <p style={{ color: '#0D92F4', marginBottom: 0 }}>
        &#40;Loading: {progress.toFixed(0)}%&#41;
      </p>
    </div>
  );
};

export default InitiativesLoader;
