import { Alert, Divider, Typography } from "antd"
import React from "react"

const { Text } = Typography;

export const ConstraintQuickGuidePopover = () => {
  return <div style={{ maxWidth: 400, }}>
    <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
      <div>
        <Text style={{ marginBottom: 0, color: 'red' }}>Basic Usage</Text>
        <div style={{ backgroundColor: '#F5F5F7', borderRadius: 15, padding: '8px 0px 8px 12px' }}>
          <p style={{ marginBottom: 0 }}>Type <kbd>@</kbd> to show ingredient list</p>
        </div>
      </div>

      <div>
        <Text style={{ marginBottom: 0, color: 'red' }}>Constraints (compositions, ingredients and groups)</Text>
        <div style={{ backgroundColor: '#F5F5F7', borderRadius: 15, padding: '8px 0px 8px 12px' }}>
          <pre style={{ marginBottom: 0 }}>@Sugar = 9</pre>
          <pre style={{ marginBottom: 0 }}>@Sugar &gt;= 9</pre>
          <pre style={{ marginBottom: 0 }}>3 &lt;= @Sugar &lt;= 9</pre>
        </div>
      </div>

      <div>
        <Text style={{ marginBottom: 0, color: 'red' }}>Creating Groups</Text>
        <div style={{ backgroundColor: '#F5F5F7', borderRadius: 15, padding: '8px 0px 8px 12px' }}>
          <pre style={{ marginBottom: 0 }}>@Sweeteners = @Sugar, @Honey</pre>
          <pre style={{ marginBottom: 0 }}>@Meat = @Brisket*0.27, @Sirloin*0.73</pre>
        </div>
      </div>

      <div>
        <Text style={{ marginBottom: 0, color: 'red' }}>Group specific constraints</Text>
        <div style={{ backgroundColor: '#F5F5F7', borderRadius: 15, padding: '8px 0px 8px 12px' }}>
          <pre style={{ marginBottom: 0 }}>@Sweeteners, min: 1, max: 2</pre>
          <pre style={{ marginBottom: 0 }}>@Sweetners, min: 2</pre>
        </div>
      </div>
    </div>
  </div>
}