import React, { useState } from 'react';
import { Modal, Button, message, notification, Spin } from 'antd';
//@ts-ignore
import styles from './upload-ingredient-specs.module.less';
import { useUploadIngredientSpecs } from '../../../network/services/ingredient-org.service';
import { useSession } from '../../../_shared/context';
import { UploadIngredientSpec } from '../../../network/interfaces/ingredient.interfaces';
import { response } from 'express';
import BlockUi from 'react-block-ui';

interface UploadSpecificationModalProps {
  visible: boolean;
  onClose: (data?: UploadIngredientSpec[]) => void;
}

const UploadSpecificationModal: React.FC<UploadSpecificationModalProps> = ({
  visible,
  onClose,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSession();
  const uploadIngredientSpecs = useUploadIngredientSpecs();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = (): void => {
    if (!selectedFile) {
      notification.error({ message: 'No file selected.' });
      return;
    }

    const uploadSpect: UploadIngredientSpec = {
      organizationId: user?.organizationId!,
      file: selectedFile
    }
    setLoading(true);
    uploadIngredientSpecs.mutate(uploadSpect, {
      onSuccess: (response) => {
        const result = response.data;
        if (result.status)
          onClose(result.data);
        else
          notification.error({ message: result.message })
      },
      onError: (response) => {
        notification.error({ message: 'Could not process your file, please try again' })
      },
      onSettled: () => {
        setLoading(false)
      }
    });

  };

  return (

    <Modal
      title="Upload Specification Document"
      visible={visible}
      footer={null}
      onCancel={() => onClose()}
      destroyOnClose
    >
      {loading && (
        <BlockUi
          tag="div"
          blocking={loading}
          loader={<Spin></Spin>}
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      <p className={styles.description}>
        Upload an ingredient specification document to automatically extract information.
      </p>

      <div className={styles.fileInputContainer}>
        <label htmlFor="specFile" className={styles.fileLabel}>
          Select File
        </label>
        <input
          id="specFile"
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          className={styles.fileInput}
        />
      </div>

      <p className={styles.supportedFormats}>
        Supported formats: PDF
      </p>

      <div className={styles.modalFooter}>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button type="primary" onClick={handleUpload} className={styles.button}>
          Upload &amp; Process
        </Button>
      </div>
    </Modal>
  );
};

export default UploadSpecificationModal;