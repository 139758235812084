import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { InfoCircleOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Tooltip, Button, Tag, Card } from "antd";
import { DropDownOptions } from "../../../../components/library/library";
import { targetObjectives } from "../../../../_shared/utils/util";

type DraggableItemProps = {
  item: any;
  index: number;
  lastItemRef: (node: HTMLDivElement | null) => void;
  listItems: any;
  activeId: string | null;
  type: DropDownOptions;
};

const getTooltipContent = (item: any, type: DropDownOptions, descriptiveFunctionalRole: string, ingredient: any) => {
  let tooltipContent = [];

  switch (type) {
    case DropDownOptions.Ingredients:
    case DropDownOptions.ProcessingConditions:
      if (descriptiveFunctionalRole && descriptiveFunctionalRole.trim()) {
        tooltipContent.push(`<b>Functional Role:</b> ${descriptiveFunctionalRole}`);
      }
      if (ingredient?.supplierIngredient?.supplierOrganization?.description) {
        tooltipContent.push(`<b>Description:</b> ${ingredient?.supplierIngredient?.supplierOrganization?.description}`);
      }
      break;

    case DropDownOptions.Outcomes:
      if (item?.lower) {
        tooltipContent.push(`<b>Lower:</b> ${item.lower} | <b>Upper:</b> ${item.upper}`);
      }
      break;

    default:
      return null;
  }

  return tooltipContent.length ? tooltipContent.join("<br>") : null;
};

const DraggableItem = ({ item, index, lastItemRef, listItems, activeId, type }: DraggableItemProps) => {
  const [expanded, setExpanded] = useState(false);
  let isDraggable = false;

  const toggleExpand = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };


  let draggableData = {};


  let draggableId = '';
  switch (item.type) {
    case DropDownOptions.Outcomes:
      draggableId = item?.name;
      isDraggable = true;
      draggableData = { id: item?.name, name: item?.name, type: type, category: item?.category, lower: item?.lower, upper: item?.upper, supplierDescription: item.supplierDescription, supplierName: item.supplierName, target: "input-output-target" };
      break;

    default:
      break;
  }
  const { attributes, listeners, setNodeRef, transform, isDragging } = isDraggable
    ? useDraggable({ id: draggableId, data: { ...draggableData, type }, })
    : { attributes: {}, listeners: {}, setNodeRef: () => { }, transform: null, isDragging: false };


  const isActive = activeId === draggableId;
  const style = transform ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` } : undefined;

  return (
    <div
      ref={(node) => {
        if (isDraggable) setNodeRef(node);
        if (index === listItems.length - 1) lastItemRef(node);
      }}
      {...(isDraggable ? listeners : {})}
      {...(isDraggable ? attributes : {})}
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "8px",
        cursor: isActive ? "grab" : "default",
        border: isActive ? "2px solid blue" : "1px solid #ccc",
        backgroundColor: isActive ? "#e0f7ff" : "#fff",
        marginBottom: "5px",
        borderRadius: "5px",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <span style={{ flexGrow: 1 }}>{item?.name}</span>
        {item?.category && <span className="tag">{item?.category}</span>}
        {item.ingredientList?.length > 0 &&
          <Button
            type="text"
            icon={expanded ? <UpOutlined /> : <DownOutlined />}
            onClick={toggleExpand}
            onPointerDown={(event) => event.stopPropagation()}
            style={{ marginLeft: "8px" }}

          />
        }
      </div>

      {expanded && item.ingredientList?.length > 0 && (
        <div style={{ marginTop: "5px", width: "100%" }}>
          {item.ingredientList.map((ingredient: any, idx: number) => (
            <DraggableSubItem key={ingredient.id} ingredient={ingredient} index={idx} item={item} type={type} />
          ))}
        </div>
      )}
      {type === "Outcomes" && (
        <div style={{ marginTop: "8px", display: "flex", gap: "6px", flexWrap: "wrap" }}>
          <Tag color="blue">Lower: {item?.lower}</Tag>
          <Tag color="red">Upper: {item?.upper}</Tag>
        </div>
      )}
    </div>
  );
};

export const DraggableSubItem = ({ ingredient, index, item, type, activeId }: { ingredient: any; index: number, item: any, type: any, activeId?: string }) => {

  // const draggableId = `${item?.id}-${index}` || item?.name;

  let draggableId = '0';
  let draggableData = {};

  switch (item.type) {
    case DropDownOptions.Ingredients:
    case DropDownOptions.ProcessingConditions:
      draggableId = `${item?.id}-${index}`;
      draggableData = { id: item.id, name: item.name, type: item.type, descriptiveFunctionalRole: item.descriptiveFunctionalRole, supplierDescription: item.supplierDescription, supplierName: item.supplierName, ingredient: ingredient, target: "input-output-target" };
      break;
    case DropDownOptions.Outcomes:
      draggableId = item?.name;
      draggableData = { id: item?.name, name: item?.name, type: type, category: item?.category, lower: item?.lower, upper: item?.upper, supplierDescription: item.supplierDescription, supplierName: item.supplierName, target: "input-output-target" };
      break;
    // case DropDownOptions.Objectives:
    //   draggableId = item.name;
    //   draggableData = { id: item.name, name: item.name, type: type, objectiveType: item.objectiveType, target: targetObjectives }
    //   break

    default:
      break;
  }


  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: draggableId,
    data: draggableData,
  });

  const isActive = activeId === draggableId;

  const style = transform
    ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` }
    : undefined;

  const tooltipContent = getTooltipContent(item, type, ingredient?.descriptiveFunctionalRole, ingredient);
  const supplierName = ingredient?.supplierIngredient?.supplierOrganization?.supplier?.name || "Unknown Supplier";
  const shortSupplierName = supplierName.length > 20 ? `${supplierName.slice(0, 20)}...` : supplierName;


  const projectName = ingredient?.project?.name || "Unknown Project";
  const shortProjectName = projectName.length > 20 ? `${projectName.slice(0, 20)}...` : projectName;



  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={{ ...style, cursor: "grab" }}>
      <Card
        size="small"
        hoverable
        style={{
          marginBottom: "10px",
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          border: isActive ? "2px solid blue" : "1px solid #ccc",
          backgroundColor: isActive ? "#e0f7ff" : "#fff",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {/* <span>Project:{ingredient?.project?.name}</span>
          <span>Supplier:{ingredient.supplierIngredient.supplierOrganization.supplier.name}</span> */}
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {projectName.length > 20 ? (
              <Tooltip title={projectName}>
                <Tag color="default"><b>Project: </b>{shortProjectName}</Tag>
              </Tooltip>
            ) : (
              <Tag color="default"><b>Project: </b>{shortProjectName}</Tag>
            )}

            {supplierName.length > 20 ? (
              <Tooltip title={supplierName}>
                <Tag color="yellow"><b>Supplier: </b>{shortSupplierName}</Tag>
              </Tooltip>
            ) : (
              <Tag color="yellow"><b>Supplier: </b>{shortSupplierName}</Tag>
            )}
          </div>
          {tooltipContent && (
            <Tooltip
              title={<span dangerouslySetInnerHTML={{ __html: getTooltipContent(item, type, ingredient?.descriptiveFunctionalRole, ingredient) }} />}
              placement="right"
              overlayStyle={{ backgroundColor: 'white', color: 'black' }}
            >
              <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff', cursor: 'pointer' }} />
            </Tooltip>
          )}
        </div>
        <div style={{ marginTop: "8px", display: "flex", gap: "6px", flexWrap: "wrap" }}>
          <Tag color="blue"><b>Lower: </b>{ingredient?.lowerLimit}</Tag>
          <Tag color="red"><b>Upper: </b>{ingredient?.upperLimit}</Tag>
          {ingredient.price != null && <Tag color="grey"><b>Cost: </b>{ingredient?.price}</Tag>}
        </div>
      </Card>
    </div>
  );
};

export default DraggableItem;
