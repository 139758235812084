import React, { useEffect, useState } from 'react';
import { Popover, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';


import './library.less';

import {
  useGetIngredientsByOrg,
  useGetProcessingConditionsByOrg,
} from '../../network/services/ingredient.service';
import { useSession } from '../../_shared/context';
import SearchableDropdownDragAndDrop from '../../_shared/components/sercheable-dropdown-drag-and-drop/searchable-dropdown-drag-and-drop';
import { useGetOutcomesByOrg } from '../../network/services/outcome.service';
import { useGetObjectiveByOrg } from '../../network/services/objective.service';
import { useGetConstraintsByOrg } from '../../network/services/constraint.service';
import { useGetIngredientCompositionsByOrganization } from '../../network/services/ingredient-composition.service';
import { useGetIngredientGroupByOrganization } from '../../network/services/ingredient-group.service';
import { useLocation } from 'react-router-dom';
import { useDragDropContext } from '../../_shared/context/drag-context';
import i18next from 'i18next';

export enum DropDownOptions {
  Ingredients = 'Ingredients',
  Outcomes = 'Outcomes',
  ProcessingConditions = 'Processing Conditions',
  Objectives = 'Objectives',
  Constraints = 'Constraints',
  Groups = 'Groups',
  Compositions = 'Compositions',
}

enum PaginationTypes {
  Ingredients = 'Ingredients',
  Outcomes = 'Outcomes',
  Objectives = 'Objectives',
  Constraints = 'Constraints',
  ProcessingConditions = 'ProcessingConditions',
  Groups = 'Groups',
  Compositions = 'Compositions',
}
enum DataStateType {
  Ingredients = 'ingredients',
  Outcomes = 'outcomes',
  Objectives = 'objectives',
  Constraints = 'constraints',
  ProcessingConditions = 'processingConditions',
  IngredientComposition = 'ingredientComposition',
  IngredientGroup = 'ingredientGroup',
}

const Library = () => {
  const limit = 10; // Number of items per page

  const [visible, setVisible] = useState(false);
  const [disableDropDown, setDisableDropDown] = useState({
    [DropDownOptions.Ingredients]: false,
    [DropDownOptions.Outcomes]: false,
    [DropDownOptions.ProcessingConditions]: false,
    [DropDownOptions.Objectives]: false,
    [DropDownOptions.Constraints]: false,
    [DropDownOptions.Groups]: false,
    [DropDownOptions.Compositions]: false,
  })
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedOutcome, setSelectedOutcomes] = useState<string[]>([]);
  const [selectedObjectives, setSelectedObjectives] = useState<string[]>([]);
  const [selectedConstraint, setSelectedConstraint] = useState<string[]>([]);
  const [selectedProcessingConditions, setSelectedProcessingConditions] = useState<string[]>([]);
  const [selectedIngredientComposition, setSelectedIngredientComposition] = useState<string[]>([]);
  const [selectedIngredientGroup, setSelectedIngredientGroup] = useState<string[]>([]);
  const { filterIngredients, filterProcessingConditions, filterOutcomes } = useDragDropContext();
  const [disableAllDropDown, setDisableAllDropDown] = useState(false);

  const { user } = useSession();
  useEffect(() => {
    refetchIngridients();
  }, [filterIngredients]);
  useEffect(() => {
    refetchProcessingConditions()
  }, [filterProcessingConditions])
  useEffect(() => {
    refetchOutcomes()
  }, [filterOutcomes])


  const location = useLocation();

  const matchNew = location.pathname.match(/\/new\/([\w-]+)/);
  const matchInspect = location.pathname.match(/\/inspect\/([\w-]+)/);
  const matchGoals = location.pathname.match(/^\/project\/([0-9a-fA-F-]+)\/explore\/([0-9a-fA-F-]+)\/goal$/);


  useEffect(() => {
    if (matchNew) {
      setDisableDropDown({
        Outcomes: false,
        "Processing Conditions": false,
        Compositions: true,
        Constraints: true,
        Groups: true,
        Ingredients: false,
        Objectives: true
      })
    }
    else if (matchInspect) {
      setDisableDropDown({
        Outcomes: true,
        "Processing Conditions": true,
        Compositions: true,
        Constraints: true,
        Groups: true,
        Ingredients: true,
        Objectives: true
      })
      setDisableAllDropDown(true)

    }
    else if (matchGoals) {
      setDisableDropDown({
        Outcomes: true,
        "Processing Conditions": true,
        Compositions: true,
        Constraints: true,
        Groups: true,
        Ingredients: true,
        Objectives: false
      })
    } else {
      setDisableDropDown({
        Outcomes: true,
        "Processing Conditions": true,
        Compositions: true,
        Constraints: true,
        Groups: true,
        Ingredients: true,
        Objectives: true
      })
    }

  }, [location])

  const initialDataState = {
    ingredients: [],
    outcomes: [],
    objectives: [],
    constraints: [],
    processingConditions: [],
    ingredientComposition: [],
    ingredientGroup: [],
  };
  const [pageOutcomes, setPageOutcomes] = useState(1);
  const [localData, setLocalData] = useState(initialDataState);

  // To update a specific data type
  const updateLocalData = (type: string, items: any[], shouldAppend = false) => {
    setLocalData((prevState) => {
      const existingItems = shouldAppend && prevState[type] ? prevState[type] : [];
      const newItems = [...existingItems, ...items];

      let uniqueItems: any[] = [];
      let filteredItems: any[] = [];

      let uniqueKey = 'id';
      switch (type) {
        case DataStateType.Outcomes:
        case DataStateType.Objectives:
        case DataStateType.Constraints:
        case DataStateType.IngredientComposition:
        case DataStateType.IngredientGroup:
          uniqueKey = 'name';
          break;
        case DataStateType.Objectives:
          uniqueKey = 'id'
      }


      switch (type) {
        case DataStateType.Ingredients:
          uniqueItems = Array.from(new Map(newItems.map(item => [item[uniqueKey], item])).values());
          filteredItems = uniqueItems.filter(item => !filterIngredients.includes(item.name));
          break;
        case DataStateType.ProcessingConditions:
          uniqueItems = Array.from(new Map(newItems.map(item => [item[uniqueKey], item])).values());
          filteredItems = uniqueItems.filter(item => !filterProcessingConditions.includes(item.name));
          break;
        case DataStateType.Outcomes:
          uniqueItems = Array.from(new Map(newItems.map(item => [item[uniqueKey], item])).values());
          filteredItems = uniqueItems.filter(item => !filterOutcomes.includes(item.name));
          break;
        default:
          filteredItems = newItems;
      }


      return {
        ...prevState,
        [type]: filteredItems,
      };
    });
  };


  const [pagination, setPagination] = useState({
    Ingredients: { page: 1, searchTerm: '' },
    Outcomes: { searchTerm: '' },
    Objectives: { page: 1, searchTerm: '' },
    Constraints: { page: 1, searchTerm: '' },
    ProcessingConditions: { page: 1, searchTerm: '' },
    Groups: { page: 1, searchTerm: '' },
    Compositions: { page: 1, searchTerm: '' },
  });

  const handleSearchChange = (type: string, value: string) => {
    updatePagination(type, 'searchTerm', value);
    updatePagination(type, 'page', 1);
  };

  const {
    data: ingredientsData,
    isError,
    isLoading: isIngredientsLoading,
    isSuccess,
    refetch: refetchIngridients,
    isFetching: isFetchingIngredients,
  } = useGetIngredientsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Ingredients.page,
    limit,
    searchTerm: pagination.Ingredients.searchTerm,
    excludedNames: filterIngredients && filterIngredients,
  });

  const { data: outcomesData, isLoading: isOutcomesLoading, refetch: refetchOutcomes, isFetching: isFetchingOutcomes } = useGetOutcomesByOrg({
    organizationId: user?.organizationId!,
    page: pageOutcomes,
    limit,
    searchTerm: pagination.Outcomes.searchTerm,
    excludedNames: filterOutcomes && filterOutcomes
  });

  const {
    data: objectivesData,
    isLoading: isObjectivesLoading,
    refetch: refetchObjectives,
    isFetching: isFetchingObjectives,
  } = useGetObjectiveByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Objectives.page,
    limit,
    searchTerm: pagination.Objectives.searchTerm,
  });

  const {
    data: constraintData,
    isLoading: isConstraintLoading,
    refetch: refetchConstraints,
    isFetching: isFetchingConstraints,
  } = useGetConstraintsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.Constraints.page,
    limit,
    searchTerm: pagination.Constraints.searchTerm,
  });

  const { data: processingConditionsData, isLoading: isProcessingConditionsLoading, refetch: refetchProcessingConditions, isFetching: isFetchingProcessingConditions } = useGetProcessingConditionsByOrg({
    organizationId: user?.organizationId!,
    page: pagination.ProcessingConditions.page,
    limit,
    searchTerm: pagination.ProcessingConditions.searchTerm,
    excludedNames: filterProcessingConditions && filterProcessingConditions,
  });

  const {
    data: ingredientCompositionData,
    isLoading: isIngredientCompositionLoading,
    refetch: refetchIngredientComposition,
    isFetching: isFetchingIngredientComposition,
  } = useGetIngredientCompositionsByOrganization({
    organizationId: user?.organizationId!,
    page: pagination.Compositions.page,
    limit,
    searchTerm: pagination.Compositions.searchTerm,
  });

  const { data: ingredientGroupData, isLoading: isIngredientGroupLoading, refetch: refetchIngredientGroup, isFetching: isFetchingIngredientGroup } = useGetIngredientGroupByOrganization({
    organizationId: user?.organizationId!,
    page: pagination.Groups.page,
    limit,
    searchTerm: pagination.Groups.searchTerm,
  });

  useEffect(() => {
    if (ingredientsData?.data?.data?.length) {
      const items = mapIngredients(ingredientsData);
      if (pagination.Ingredients.page === 1) {
        updateLocalData(DataStateType.Ingredients, items);
      }
      else {
        updateLocalData(DataStateType.Ingredients, items, true);
      }
    } else {
      updateLocalData(DataStateType.Ingredients, []);
    }
  }, [ingredientsData]);

  useEffect(() => {
    if (outcomesData?.data?.pagination?.total) {
      const items = mapOutcomes(outcomesData);
      if (pageOutcomes === 1) {
        updateLocalData(DataStateType.Outcomes, items);
      } else {
        updateLocalData(DataStateType.Outcomes, items, true);
      }
    } else {
      updateLocalData(DataStateType.Outcomes, []);
    }
  }, [outcomesData]);

  useEffect(() => {
    if (objectivesData?.data?.data?.length) {
      const items = mapObjectives(objectivesData);
      if (pagination.Objectives.page === 1) {
        updateLocalData(DataStateType.Objectives, items);
      } else {
        updateLocalData(DataStateType.Objectives, items, true);
      }
    } else {
      updateLocalData(DataStateType.Objectives, []);
    }
  }, [objectivesData]);

  useEffect(() => {
    if (constraintData?.data?.data?.length) {
      const items = mapConstraints(constraintData);
      if (pagination.Constraints.page === 1) {
        updateLocalData(DataStateType.Constraints, items);
      } else {
        updateLocalData(DataStateType.Constraints, items, true);
      }
    } else {
      updateLocalData(DataStateType.Constraints, []);
    }
  }, [constraintData]);

  useEffect(() => {
    if (processingConditionsData?.data?.data?.length) {
      const items = mapProcessing(processingConditionsData);
      if (pagination.ProcessingConditions.page === 1) {
        updateLocalData(DataStateType.ProcessingConditions, items);
      } else {
        updateLocalData(DataStateType.ProcessingConditions, items, true);
      }
    } else {
      updateLocalData(DataStateType.ProcessingConditions, []);
    }
  }, [processingConditionsData]);

  useEffect(() => {
    if (ingredientCompositionData?.data?.data?.length) {
      const items = mapIngredientComposition(ingredientCompositionData);
      if (pagination.Compositions.page === 1) {
        updateLocalData(DataStateType.IngredientComposition, items);
      } else {
        updateLocalData(DataStateType.IngredientComposition, items, true);
      }
    } else {
      updateLocalData(DataStateType.IngredientComposition, []);
    }
  }, [ingredientCompositionData]);

  useEffect(() => {
    if (ingredientGroupData?.data?.data?.length) {
      const items = mapIngredientGroup(ingredientGroupData);
      if (pagination.Groups.page === 1) {
        updateLocalData(DataStateType.IngredientGroup, items);
      } else {
        updateLocalData(DataStateType.IngredientGroup, items, true);
      }
    } else {
      updateLocalData(DataStateType.IngredientGroup, []);
    }
  }, [ingredientGroupData]);

  const updatePagination = (type: any, key: any, value: any) => {
    if (type === PaginationTypes.Outcomes) {
      setPageOutcomes(value);
    }
    setPagination(prev => ({
      ...prev,
      [type]: { ...prev[type], [key]: value },
    }));
  };

  const fetchMoreIngredient = () =>
    fetchMore(
      PaginationTypes.Ingredients,
      isIngredientsLoading,
      ingredientsData,
      PaginationTypes.Ingredients,
      refetchIngridients
    );
  const fetchMoreOutcome = () =>
    fetchMore(
      PaginationTypes.Outcomes,
      isOutcomesLoading,
      outcomesData,
      PaginationTypes.Outcomes,
      refetchOutcomes
    );
  const fetchMoreObjectives = () =>
    fetchMore(
      PaginationTypes.Objectives,
      isObjectivesLoading,
      objectivesData,
      PaginationTypes.Objectives,
      refetchObjectives
    );
  const fetchMoreConstraints = () =>
    fetchMore(
      PaginationTypes.Constraints,
      isConstraintLoading,
      constraintData,
      PaginationTypes.Constraints,
      refetchConstraints
    );
  const fetchMoreProcessingConditions = () =>
    fetchMore(
      PaginationTypes.ProcessingConditions,
      isProcessingConditionsLoading,
      processingConditionsData,
      PaginationTypes.ProcessingConditions,
      refetchProcessingConditions
    );
  const fetchMoreIngredientComposition = () =>
    fetchMore(
      PaginationTypes.Compositions,
      isIngredientCompositionLoading,
      ingredientCompositionData,
      PaginationTypes.Compositions,
      refetchIngredientComposition
    );
  const fetchMoreIngredientGroup = () =>
    fetchMore(
      PaginationTypes.Groups,
      isIngredientGroupLoading,
      ingredientGroupData,
      PaginationTypes.Groups,
      refetchIngredientGroup
    );

  const fetchMore = (
    type: PaginationTypes,
    isLoading: any,
    data: any,
    paginationKey: any,
    functionRefetch: any
  ) => {
    if (
      isLoading ||
      !(data?.data?.pagination?.page < data?.data?.pagination?.totalPages)
    )
      return; // Prevent fetch if loading or already on last page
    if (type === PaginationTypes.Outcomes) {
      setPageOutcomes(prev => prev + 1);
    } else {
      updatePagination(type, 'page', pagination[paginationKey].page + 1);
    }
    functionRefetch();
  };

  //#region Map

  const mapIngredients = (localIngredientData: any) => {
    if (localIngredientData) {
      const items = localIngredientData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        type: DropDownOptions.Ingredients,
        descriptiveFunctionalRole: item.descriptiveFunctionalRole,
        supplierDescription: item.supplierDescription,
        supplierName: item.supplierName,
        supplier: item.SupplierIngredient,
        ingredientList: item.ingredientList,
        unit: item.unit,
        cost: item.price,
        costUnit: item.costUnit,
        category: item.category,
        // typeIngredient: item.type
      }));
      return items;
    }
  };
  const mapProcessing = (localIngredientData: any) => {
    if (localIngredientData) {
      const items = localIngredientData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        type: DropDownOptions.ProcessingConditions,
        descriptiveFunctionalRole: item.descriptiveFunctionalRole,
        supplierDescription: item.supplierDescription,
        supplierName: item.supplierName,
        supplier: item.SupplierIngredient,
        ingredientList: item.ingredientList.ingredientList,
        unit: item.unit,
        cost: item.price,
        costUnit: item.costUnit,
        category: item.category,
        typeIngredient: item.type
      }));
      return items;
    }
  };
  const mapOutcomes = (outcomesData: any) => {
    if (outcomesData) {
      const items = outcomesData.data.data.map(item => ({
        id: item.id,
        name: item.targetVariable,
        category: item.OutcomeCategory.name,
        lower: item.lower,
        upper: item.upper,
        type: DropDownOptions.Outcomes,
      }));
      return items;
    }
  };
  const mapConstraints = (constraintData: any) => {
    if (constraintData) {
      const items = constraintData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  const mapObjectives = (objectivesData: any) => {
    if (objectivesData) {
      const items = objectivesData.data.data.map(item => ({
        id: item.id,
        name: item.targetVariable,
        objectiveType: item.objectiveType,
        lower: item?.lower,
        upper: item?.upper,
        value: item?.value
      }));
      return items;
    }
  };
  const mapIngredientComposition = (ingredientCompositionData: any) => {
    if (ingredientCompositionData) {
      const items = ingredientCompositionData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  const mapIngredientGroup = (ingredientGroupData: any) => {
    if (ingredientGroupData) {
      const items = ingredientGroupData.data.data.map(item => ({
        id: item.id,
        name: item.name,
        constraintType: item.constraintType,
      }));
      return items;
    }
  };
  //#endregion

  return (
    <Popover
      content={
        <Space>
          <SearchableDropdownDragAndDrop
            listItems={localData.ingredients}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            emptytext={t('library.drop-down.ingredient.empty')}
            placeholder={t('library.drop-down.ingredient.placeHolder')}
            title={t('library.drop-down.ingredient.title')}
            // key={0}
            isLoading={isFetchingIngredients}
            hasMore={
              ingredientsData?.data?.pagination?.page <
              ingredientsData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreIngredient}
            searchTerm={pagination.Ingredients.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Ingredients, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Ingredients, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchIngridients}
            type={DropDownOptions.Ingredients}
            disable={disableDropDown[DropDownOptions.Ingredients]}
          />
          <SearchableDropdownDragAndDrop
            listItems={localData.outcomes}
            setSelectedItems={setSelectedOutcomes}
            selectedItems={selectedOutcome}
            emptytext={t('library.drop-down.outcomes.empty')}
            placeholder={t('library.drop-down.outcomes.placeHolder')}
            title={t('library.drop-down.outcomes.title')}
            key={1}
            isLoading={isFetchingOutcomes}
            hasMore={
              outcomesData?.data?.pagination?.page <
              outcomesData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreOutcome}
            searchTerm={pagination.Outcomes.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.Outcomes, value)
            }
            setPage={value =>
              updatePagination(PaginationTypes.Outcomes, 'page', value)
            }
            refetchIngridientsSearchTerm={refetchOutcomes}
            type={DropDownOptions.Outcomes}
            disable={disableDropDown[DropDownOptions.Outcomes]}
          />
          {/* {!disableDropDown[DropDownOptions.Objectives] &&
            <SearchableDropdownDragAndDrop
              listItems={localData.objectives}
              setSelectedItems={setSelectedObjectives}
              selectedItems={selectedObjectives}
              emptytext={t('library.drop-down.objectives.empty')}
              placeholder={t('library.drop-down.objectives.placeHolder')}
              title={t('library.drop-down.objectives.title')}
              // key={2}
              isLoading={isFetchingObjectives}
              hasMore={
                objectivesData?.data?.pagination?.page <
                objectivesData?.data?.pagination?.totalPages
              }
              refetchIngridients={fetchMoreObjectives}
              searchTerm={pagination.Objectives.searchTerm}
              setSearchTerm={value =>
                handleSearchChange(PaginationTypes.Objectives, value)
              }
              setPage={value =>
                updatePagination(PaginationTypes.Objectives, 'page', value)
              }
              refetchIngridientsSearchTerm={refetchObjectives}
              type={DropDownOptions.Objectives}
              disable={disableDropDown[DropDownOptions.Objectives]}

            />
          }
          {!disableDropDown[DropDownOptions.Constraints] &&
            <SearchableDropdownDragAndDrop
              listItems={localData.constraints}
              setSelectedItems={setSelectedConstraint}
              selectedItems={selectedConstraint}
              emptytext={t('library.drop-down.constraint.empty')}
              placeholder={t('library.drop-down.constraint.placeHolder')}
              title={t('library.drop-down.constraint.title')}
              // key={3}
              isLoading={isFetchingConstraints}
              hasMore={
                constraintData?.data?.pagination?.page <
                constraintData?.data?.pagination?.totalPages
              }
              refetchIngridients={fetchMoreConstraints}
              searchTerm={pagination.Constraints.searchTerm}
              setSearchTerm={value =>
                handleSearchChange(PaginationTypes.Constraints, value)
              }
              setPage={value =>
                updatePagination(PaginationTypes.Constraints, 'page', value)
              }
              refetchIngridientsSearchTerm={refetchConstraints}
              type={DropDownOptions.Constraints}
              disable={disableDropDown[DropDownOptions.Constraints]}

            />
          } */}
          <SearchableDropdownDragAndDrop
            listItems={localData.processingConditions}
            setSelectedItems={setSelectedProcessingConditions}
            selectedItems={selectedProcessingConditions}
            emptytext={t('library.drop-down.processing-conditions.empty')}
            placeholder={t('library.drop-down.processing-conditions.placeHolder')}
            title={t('library.drop-down.processing-conditions.title')}
            // key={4}
            isLoading={isFetchingProcessingConditions}
            hasMore={
              processingConditionsData?.data?.pagination?.page <
              processingConditionsData?.data?.pagination?.totalPages
            }
            refetchIngridients={fetchMoreProcessingConditions}
            searchTerm={pagination.ProcessingConditions.searchTerm}
            setSearchTerm={value =>
              handleSearchChange(PaginationTypes.ProcessingConditions, value)
            }
            setPage={value =>
              updatePagination(
                PaginationTypes.ProcessingConditions,
                'page',
                value
              )
            }
            refetchIngridientsSearchTerm={refetchProcessingConditions}
            type={DropDownOptions.ProcessingConditions}
            disable={disableDropDown[DropDownOptions.ProcessingConditions]}
          />
          {/* {!disableDropDown[DropDownOptions.Compositions] &&
            <SearchableDropdownDragAndDrop
              listItems={localData.ingredientComposition}
              setSelectedItems={setSelectedIngredientComposition}
              selectedItems={selectedIngredientComposition}
              emptytext={t('library.drop-down.ingredient-composition.empty')}
              placeholder={t('library.drop-down.ingredient-composition.placeHolder')}
              title={t('library.drop-down.ingredient-composition.title')}
              // key={5}
              isLoading={isFetchingIngredientComposition}
              hasMore={
                ingredientCompositionData?.data?.pagination?.page <
                ingredientCompositionData?.data?.pagination?.totalPages
              }
              refetchIngridients={fetchMoreIngredientComposition}
              searchTerm={pagination.Compositions.searchTerm}
              setSearchTerm={value =>
                handleSearchChange(PaginationTypes.Compositions, value)
              }
              setPage={value =>
                updatePagination(PaginationTypes.Compositions, 'page', value)
              }
              refetchIngridientsSearchTerm={refetchIngredientComposition}
              type={DropDownOptions.Compositions}
              disable={disableDropDown[DropDownOptions.Compositions]}
            />
          }
          {!disableDropDown[DropDownOptions.Groups] &&
            <SearchableDropdownDragAndDrop
              listItems={localData.ingredientGroup}
              setSelectedItems={setSelectedIngredientGroup}
              selectedItems={selectedIngredientGroup}
              emptytext={t('library.drop-down.ingredient-group.empty')}
              placeholder={t('library.drop-down.ingredient-group.placeHolder')}
              title={t('library.drop-down.ingredient-group.title')}
              // key={6}
              isLoading={isFetchingIngredientGroup}
              hasMore={
                ingredientGroupData?.data?.pagination?.page <
                ingredientGroupData?.data?.pagination?.totalPages
              }
              refetchIngridients={fetchMoreIngredientGroup}
              searchTerm={pagination.Groups.searchTerm}
              setSearchTerm={value =>
                handleSearchChange(PaginationTypes.Groups, value)
              }
              setPage={value =>
                updatePagination(PaginationTypes.Groups, 'page', value)
              }
              refetchIngridientsSearchTerm={refetchIngredientGroup}
              type={DropDownOptions.Groups}
              disable={disableDropDown[DropDownOptions.Groups]}
            />
          } */}

        </Space>
      }
      trigger="click"
      placement="left"
      open={visible}
      onOpenChange={open => setVisible(open)}
    >
      <Button
        type="primary"
        style={{
          position: 'fixed',
          right: 0,
          top: 200,
          zIndex: 1000,
          opacity: 0.8,
        }}
      >
        {t('library.title')}

      </Button>
    </Popover>
  );
};

export default Library;
