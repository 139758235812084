import React from 'react';
import Icon, { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Input, Button, Space, Table, notification, InputNumber, Tooltip } from 'antd';
//@ts-ignore
import styles from './add-ingredient.module.less';
import { Info } from '../../../_shared/style';

type CharacteristicsTableProps = {
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  setValues: (values: any) => void;
  push: (item: any) => void;
  remove: (index: number) => void;
};

const AddCharacteristicsTable: React.FC<CharacteristicsTableProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setValues,
  push,
  remove,
}) => {
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button className={styles.boderButon} onClick={() => push({ id: Date.now(), name: '', type: '', value: '', unit: '', isNew: true })} style={{ marginBottom: '16px' }}>
          + Add Characteristic
        </Button>
        <Tooltip title="Add properties like pH, viscosity, etc.">
          <span style={{ marginLeft: '10px' }}>
            <Info />
          </span>
        </Tooltip>
      </div>
      <Table
        dataSource={values.characteristics}
        rowKey="id"
        rowClassName={(record, index) => {
          const hasError =
            errors.characteristics?.[index] &&
            Object.keys(errors.characteristics[index]).length > 0 &&
            touched.characteristics?.[index] &&
            !record.isNew &&
            editingIndex !== index;

          return `${styles.compactRow} ${hasError ? styles.rowWithError : ''}`;
        }}
        columns={[
          {
            title: 'Characteristic',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, record: any, index: number) => (
              record.isNew || editingIndex === index ? (
                <Form.Item className={styles.characteristicInput}
                  validateStatus={
                    touched.characteristics?.[index]?.name &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.name ? 'error' : ''
                  }
                  help={
                    touched.characteristics?.[index]?.name &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.name
                      ? errors.characteristics[index].name
                      : null
                  }
                >
                  <Input
                    name={`characteristics[${index}].name`}
                    value={values.characteristics[index].name}
                    className={styles.customAntInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>
              ) : (
                <span>{record.name}</span>
              )
            ),
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_: any, record: any, index: number) => (
              record.isNew || editingIndex === index ? (
                <Form.Item className={styles.characteristicInput}
                  validateStatus={
                    touched.characteristics?.[index]?.type &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.type ? 'error' : ''
                  }
                  help={
                    touched.characteristics?.[index]?.type &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.type
                      ? errors.characteristics[index].type
                      : null
                  }
                >
                  <Input
                    name={`characteristics[${index}].type`}
                    value={values.characteristics[index].type}
                    className={styles.customAntInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>
              ) : (
                <span>{record.type}</span>
              )
            ),
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (_: any, record: any, index: number) => (
              record.isNew || editingIndex === index ? (
                <Form.Item className={styles.characteristicInput}
                  validateStatus={touched.characteristics?.[index]?.value &&
                    typeof errors.characteristics?.[index] === 'object' &&
                    errors.characteristics?.[index]?.value ? 'error' : ''
                  }
                  help={
                    touched.characteristics?.[index]?.value &&
                      (typeof errors.characteristics?.[index] === 'object' &&
                        errors.characteristics?.[index]?.value)
                      ? errors.characteristics[index].value
                      : (isNaN(Number(values.characteristics[index].value)) ? 'Value must be a number' : null)
                  }
                >
                  <Input
                    name={`characteristics[${index}].value`}
                    value={values.characteristics[index].value}
                    className={styles.customAntInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>
              ) : (
                <span>{record.value}</span>
              )
            ),
          },
          {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            render: (_: any, record: any, index: number) => (
              record.isNew || editingIndex === index ? (
                <Form.Item className={styles.characteristicInput}
                  validateStatus={
                    touched.characteristics?.[index]?.unit &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.unit ? 'error' : ''
                  }
                  help={
                    touched.characteristics?.[index]?.unit &&
                      typeof errors.characteristics?.[index] === 'object' &&
                      errors.characteristics?.[index]?.unit
                      ? errors.characteristics[index].unit
                      : null
                  }
                >
                  <Input
                    name={`characteristics[${index}].unit`}
                    value={values.characteristics[index].unit}
                    className={styles.customAntInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>
              ) : (
                <span>{record.unit}</span>
              )
            ),
          },
          {
            title: '',
            key: 'action',
            render: (_: any, record: any, index: number) => (
              <>
                {record.isNew || editingIndex === index ? (
                  <>
                    <Button className={styles.customLink} disabled={errors.characteristics?.[index]} type="link" icon={<SaveOutlined />} onClick={() => {
                      if (values.characteristics[index].name && values.characteristics[index].type && values.characteristics[index].value && values.characteristics[index].unit) {
                        setEditingIndex(null);
                        record.isNew = false;
                        setValues({ ...values });
                      } else {
                        notification.error({ message: 'Please fill all fields before saving.' });
                      }
                    }}>
                      Save
                    </Button>
                    <Button className={styles.customLink} type="link" icon={<CloseOutlined />} onClick={() => record.isNew ? remove(index) : setEditingIndex(null)}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className={styles.customLink} type="link" icon={<EditOutlined />} onClick={() => setEditingIndex(index)}>
                      Edit
                    </Button>
                    <Button className={styles.customLink} type="link" icon={<DeleteOutlined />} onClick={() => remove(index)}>
                      Remove
                    </Button>
                  </>
                )}
              </>
            ),
          },
        ]}
        locale={{
          emptyText: (
            <div style={{ textAlign: 'center' }}>
              <p>No characteristics added yet</p>
              {/* <Button className={styles.customLink} type="link" onClick={() => push({ id: Date.now(), name: '', type: '', value: '', unit: '', isNew: true })}>
                + Add Characteristic
              </Button> */}
            </div>
          )
        }}
        pagination={false}
        style={{ marginBottom: '16px' }}
      />
    </>
  );
};

export default AddCharacteristicsTable;
