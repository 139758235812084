import { VariableType } from "@prisma/client";

export interface IngredientInfo {
  name: string;
  category: CategoryIn;
  uLimit: number;
  lLimit: number;
  price: number;
  isTestcondition: boolean;
  unit: string;
  id?: number;
  ingredientId?: number;
  type?: string;
  values?: string[];
}

interface CategoryIn {
  name: string;
  color: string;
}

export interface CreateIngredientOut extends GetIngredientOut {
  ingredient: IngredientInfo;
}

export interface GetIngredientOut {
  organizationId?: string;
  projectId?: string;
}

export interface IngredientFromServer {
  ingredient?: {
    id: number;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  ingredientId: number;
  lowerLimit: number;
  upperLimit: number;
  price: number;
  isTestCondition: boolean;
  unit: string;
  type?: string;
  values?: string;
}
export interface GetIngredientOut {
  organizationId?: string;
  projectId?: string;
}

export interface UploadIngredientSpec {
  id?: string;
  organizationId: string;
  file: File;
  ingredientsSpec?: IngredientSpecs
}

export interface IngredientSpecs {
  ingredientName: string;
  supplier: string;
  functionalRole: string;
  unit: string;
  externalCode: string;
  type: VariableType | undefined;
  category: string,
  description: string;
  price: number | undefined;
  upperLimit: number | undefined;
  lowerLimit: number | undefined;
  value: string;
  characteristics: IngredientCharacteristic[]
}

export interface IngredientCharacteristic {
  isNew: boolean;
  name: string;
  unit: string;
  range?: {
    upperBound?: string;
    lowerBound?: string;
  };
  tolerance?: {
    upperBound?: string;
    lowerBound?: string;
  };
  threshold?: string;
  value: string;
  type?: string;
}


export interface CreateIngredientOrgIn {
  id?: string;
  name: string;
  supplier: string;
  description: string;
  price: number;
  unit: string;
  externalCode: string;
  functionalRole?: string;
  lowerLimit?: number;
  upperLimit?: number;
  value?: string;
  type: VariableType;
  category: string;
  characteristics: CharacteristicIn[];
}


export interface CreateIngredientOrgData extends CreateIngredientOrgIn {
  organizationId: string;
}

export interface CharacteristicIn {
  name: string;
  unit: string;
  description: string;
  value: string;
  upperBound?: string;
  lowerBound?: string;
  type?: string;
  toleranceUpperBound?: string;
  toleranceLowerBound?: string;
  threshold?: string;
}

export const variableTypeLabels = {
  'NUMERIC': 'Continuous',
  'CATEGORICAL': 'Nominal',
  'ORDINAL': 'Ordinal'
}