import React, { useEffect, useRef, useState } from 'react';
import { ConstraintEditor } from './constraint-editor';
import { ConstraintList } from './constraint-list';
import { Card, Collapse, Divider, Modal, Switch, Tag } from 'antd';
import { CollapseProps } from 'antd/lib';
import { AimOutlined, LockOutlined } from '@ant-design/icons';
import './exploration-constraints-settings-v3.less'
import { ConstraintInputType } from '__generated__/globalTypes';

export const ExplorationConstraintSettingV3 = () => {


  const [editingConstraint, setEditingConstraint] = useState<ConstraintInputType | undefined>();


  const items: CollapseProps['items'] = [
    // {
    //   key: 'objectives',
    //   label: <div style={{ gap: 10, display: 'flex', alignItems: 'center' }}><span>Objectives</span><AimOutlined /></div>,
    //   children: (
    //     <div>
    //     </div>
    //   )
    // },
    {
      key: 'constraints',
      label: <div style={{ gap: 10, display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: 8 }}>
          <span>Constraints</span><LockOutlined /><Tag>Beta</Tag>
        </div>
      </div>,
      children: (
        <div>
          <ConstraintEditor
            editingConstraint={editingConstraint}
            setEditingConstraint={setEditingConstraint}
          />
          <Divider />
          <ConstraintList
            setEditingConstraint={setEditingConstraint}
          />
        </div>
      )
    }
  ];


  return <>
    <Collapse items={items} />
  </>;
};