import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Dropdown, Input, Spin } from 'antd';
import { debounce } from 'lodash';
import { DragOverlay } from '@dnd-kit/core';
import { DropDownOptions } from '../../../components/library/library';
import './searchable-dropdown-drag-and-drop.less';
import DraggableItem, { DraggableSubItem } from './draggable-item/draggable-item';
import { useDragDropContext } from '../../../_shared/context/drag-context';

type IProps = {
  listItems: { name: string; category?: string }[];
  setSelectedItems: any;
  selectedItems: any;
  title: string;
  placeholder: string;
  emptytext: string;
  isLoading: boolean;
  refetchIngridients: () => void;
  hasMore: boolean;
  searchTerm: string;
  setSearchTerm: (item: string) => void;
  setPage: (item: number) => void;
  refetchIngridientsSearchTerm: () => void;
  type: DropDownOptions;
  disable?: boolean;
};

const SearchableDropdownDragAndDrop = ({
  listItems,
  refetchIngridients,
  emptytext,
  placeholder,
  title,
  isLoading,
  searchTerm,
  setSearchTerm,
  setPage,
  refetchIngridientsSearchTerm,
  type,
  disable = false
}: IProps) => {

  const observer = useRef<IntersectionObserver | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [localItems, setLocalItems] = useState<any>([]);
  const { draggedItem } = useDragDropContext();
  const [typeDraggedItem, setTypeDraggedItem] = useState<string>();

  useEffect(() => {
    if (listItems.length)
      setLocalItems(addDraggableId(listItems))
    else {
      setLocalItems([])
    }
  }, [listItems])

  const addDraggableId = (listItems: any[]) => {

    return listItems.map((item, index) => ({
      ...item,
      draggableId: crypto.randomUUID()
    }));
  };

  const fetchMore = () => {
    refetchIngridients();
  };

  const debouncedSearch = useCallback(
    debounce(term => {
      setPage(1);
      refetchIngridientsSearchTerm();
    }, 500),
    []
  );

  useEffect(() => {
    if (searchTerm) debouncedSearch(searchTerm);
  }, [searchTerm]);

  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {

      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            console.log('Fetching more data...');
            fetchMore();
          }
        },
        { threshold: 1 }
      );

      if (node) observer.current.observe(node);
    },
    [isLoading]
  );
  const findItemByDraggableId = (draggableItemId: string, localItems: any[]) => {
    const parsed = parseDraggableItemId(draggableItemId);
    if (!parsed.id) return null;
    return localItems.find(i => i.id === parsed.id || i.name === parsed.id) || null;
  };


  // Helper function to parse the draggable ID
  const parseDraggableItemId = (draggableItemId: string): { id: number | null; index: string | null } => {
    if (!draggableItemId) return { id: null, index: null };

    const [id, index] = draggableItemId.split("-");
    return { id: Number(id) ?? null, index: index ?? null };
  };

  const findIngredientByDraggableId = (draggableItemId: string, localItems: any[]) => {
    const parsed = parseDraggableItemId(draggableItemId);
    if (!parsed.id || parsed.index === null) return null;

    const item = localItems.find(i => i.id === parsed.id || i.name === parsed.id);
    if (!item || !Array.isArray(item.ingredientList)) return null;

    const index = Number(parsed.index);
    const val = isNaN(index) || index < 0 || index >= item.ingredientList.length
      ? null
      : item.ingredientList[index];

    return val;
  };

  return (
    <Dropdown
      overlay={
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            width: 300,
          }}
        >
          <div
            style={{
              padding: '10px',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <Input
              placeholder={placeholder}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <div
            style={{
              maxHeight: 300,
              overflowY: 'auto',
              padding: '10px',
            }}
          >

            {localItems?.length ? (
              localItems.map((item, index) =>
              (
                <DraggableItem
                  key={`${item.name}`}
                  item={item}
                  index={index}
                  lastItemRef={lastItemRef}
                  listItems={localItems}
                  activeId={null}
                  type={type}

                />
              )
              )
            ) : !isLoading ? (
              <div style={{ color: 'gray' }}>{emptytext}</div>
            ) : (
              <Spin
                style={{ display: 'block', textAlign: 'center', marginTop: 10 }}
              />
            )}
            {/* <DragOverlay>
              {draggedItemId ? <DraggableItem item={localItems.find(i => i.id === draggedItemId || i.name === draggedItemId)} index={0} listItems={[]} activeId={draggedItemId} lastItemRef={() => { }} /> : null}
            </DragOverlay> */}

            <DragOverlay>
              {draggedItem && (() => {
                const { type } = draggedItem.data || {};
                const { id } = draggedItem || {};

                if (type === DropDownOptions.Outcomes) {
                  let esto = localItems.find(i => i.id === draggedItem.id || i.name === draggedItem.id)
                  return (
                    <DraggableItem
                      item={esto}
                      index={0}
                      listItems={[]}
                      activeId={id}
                      lastItemRef={() => { }}
                      type={DropDownOptions.Outcomes}
                    />
                  );
                }

                const isValidType = type === DropDownOptions.Ingredients || type === DropDownOptions.ProcessingConditions;
                if (!isValidType) return null;

                return (
                  <DraggableSubItem
                    ingredient={findIngredientByDraggableId(id, localItems)}
                    index={0}
                    item={findItemByDraggableId(id, localItems)}
                    type="yourType"
                    activeId={id}
                  />
                );
              })()}
            </DragOverlay>

          </div>
        </div>
      }
      trigger={['click']}
      overlayClassName="custom-ropdown"
      disabled={disable}
    >
      <Button style={{ marginLeft: 10 }}>
        {title}{' '}
        <span style={{ color: '#1890ff', marginLeft: 10 }}>
          {selectedItems.length && selectedItems[0] !== 'All'
            ? selectedItems.length
            : ''}
        </span>
      </Button>
    </Dropdown>
  );
};

export default SearchableDropdownDragAndDrop;
