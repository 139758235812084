import { BaseClient } from '../ApiClient';
import {
  CreateIngredientOrgData,
  IngredientFromServer,
  UploadIngredientSpec,
} from '../interfaces/ingredient.interfaces';

const ENDPOINTS = {
  postCreateIngredientUrl: (org_id: string) =>
    `org/${org_id}/ingredient`,
  putCreateIngredientUrl: (org_id: string) =>
    `org/${org_id}/ingredient`,
  postUploadIngredientSpecUrl: (org_id: string) =>
    `org/${org_id}/ingredient/upload`,
  getIngredientsByOrg: (org_id: string) => `org/${org_id}/ingredient`
};

const postCreateIngredient = (data: CreateIngredientOrgData) => {
  return BaseClient.post<CreateIngredientOrgData, IngredientFromServer>(
    ENDPOINTS.postCreateIngredientUrl(data.organizationId!),
    data
  );
};

const putCreateIngredient = (data: CreateIngredientOrgData) => {
  return BaseClient.put<CreateIngredientOrgData, IngredientFromServer>(
    ENDPOINTS.putCreateIngredientUrl(data.organizationId!),
    data
  );
};

const postUploadIngredientSpec = (data: UploadIngredientSpec) => {
  const formData = new FormData();
  formData.append("pdfs", data.file);
  return BaseClient.post<FormData, UploadIngredientSpec[]>(
    ENDPOINTS.postUploadIngredientSpecUrl(data.organizationId!),
    formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  );
};

const getIngredientsByOrg = ({ queryKey }: any) => {
  const [, organizationId, page, pageSize, sortField, sortOrder, searchTerm] = queryKey;
  return BaseClient.get(ENDPOINTS.getIngredientsByOrg(organizationId), {
    params: {
      page,
      pageSize,
      sortField,
      sortOrder,
      searchTerm: searchTerm || ''
    },
  });
};

export const IngredientOrgRepository = {
  postCreateIngredient,
  putCreateIngredient,
  postUploadIngredientSpec,
  getIngredientsByOrg
};
